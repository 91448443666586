import React, { useState, useEffect, useContext } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DocsF from "../components/docsForm2";
import { Button } from "react-bootstrap";
import { LoginContext } from "../helpers/context";
import Alert from "react-bootstrap/Alert";
import Image from "react-bootstrap/Image";
import docImgade from "../images/docSample.jpg";
import Container from "react-bootstrap/Container";
import OrderForm from "../components/orderForm";
import Docs_Descriptions from "../helpers/docs_descriptions";
import SharedDataOrderContext from "../helpers/sharedOrderContext";
import Modal_Docs_pdf from "../components/dowloadpdf_modal";
import secureLocalStorage from "react-secure-storage";
import OrderFormTuCompra from "../components/orderForm_tuCompra";

const MainDocuments = () => {
  const { urlDownload, setDownloadURl } = useContext(LoginContext);

  const [currentDoc, setCurrentDoc] = useState("T_doc1");

  const [currenDocName, setCurrentDocName] = useState(
    "Solicitud de copias – Sanción de tránsito"
  );

  const [index, setIndex] = useState(0);

  const [currentDocFormData, setCurrentDocFormData] = useState({});

  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  const { sharedOrderData, updateSharedData } = useContext(
    SharedDataOrderContext
  );

  const [currentDocDesc, setCurrentDocDesc] = useState("");

  const [showDownloadButton, setDownloadButton] = useState(false);

  const [docDownloadName, setDocDownloadName] = useState(false);

  const [docButtonCounter, setDocButtonCounter] = useState(false);

  const [showDocItems, setDocsItems] = useState("Predial");

  const [predialDocs, setPredialDocs] = useState(true);

  const [transitoDocs, setTransitoDocs] = useState(true);

  const [industriaDocs, setIndustriaDocs] = useState(true);

  const [seeMore, setSeeMore] = useState(null);

  const [alert, setAlert] = useState(false);

  const [docDownloadedAlert, setdocDownloadedAlert] = useState(false);

  const [docButton, setDocButton] = useState("first");

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
      //console.log(screenSize);
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    if (secureLocalStorage.getItem("docUrl") !== null) {
      getDocUrl(secureLocalStorage.getItem("docUrl"));
      setTimeout(() => {
        setdocDownloadedAlert(false);
        secureLocalStorage.clear();
      }, 2000);
    }

    if (secureLocalStorage.getItem("docName") !== null) {
      getDocDownloadName(secureLocalStorage.getItem("docName"));
      secureLocalStorage.clear();
    }
  }, []);

  useEffect(() => {
    const element = document.querySelector("#step-" + docButton);
    //console.log(element);
    if (element && docButtonCounter) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      setDocButtonCounter(true);
    }
  }, [docButton]);

  const getDocUrl = (docUrl) => {
    setDownloadURl(docUrl);
    setDocButton("fifth");
  };

  const getDocDownloadName = (docFinalName) => {
    setDocDownloadName(docFinalName);
  };

  const handleDocsButton = (clicked) => {
    setDocButton(clicked);

    //console.log(docButton);
  };

  const handleDocCardClick = (docClicked, docName, price, desc) => {
    setCurrentDoc(docClicked);
    setCurrentDocName(docName);
    setCurrentDocDesc(desc);
    updateSharedData({ description: docName, amount: price });
    setDocButton("third");
    setIndex(0);
    //console.log('documento clickeado '+ docClicked);
  };

  const handleDownloadClick = () => {
    let doc_Name = currenDocName;
    const link = document.createElement("a");
    link.href = urlDownload;
    link.setAttribute("download", docDownloadName);
    document.body.appendChild(link);
    link.click();
    setDownloadButton(true);
    setDocButton("first");
    setSeeMore(true);
    secureLocalStorage.clear();
  };

  const handleDownloadSucces = () => {
    setdocDownloadedAlert(false);
  };

  const handlePayment = () => {
    setAlert(true);

    setTimeout(() => {
      setAlert(false);
      setDocButton("fourth");
    }, 2000);
  };

  const HandleSeeMore = (info) => {
    setSeeMore(info);
  };

  return (
    <div id="mainDocuments" className="">
      <Container fluid>
        <Row className="justify-content-center mt-5 mb-5">
          <Col xs={12} md={10} className="text-center docs_main_text">
            <p>
              Con el objetivo de facilitar tus trámites y procesos ante
              entidades públicas, hemos diseñado diferentes modelos que
              simplifican el proceso legal al proporcionar formularios sencillos
              y fáciles de usar que te guiarán paso a paso en la creación de
              documentos legales.
            </p>
            <p>
              Además ofrecemos soporte y asesoría en linea para resolver tus
              preguntas legales <a href="">Contáctanos</a>.
            </p>
          </Col>
        </Row>
        <Row className="d-flex align-items-top justify-content-center  mt-5 mb-5">
          <Col xs={12} md={10} className="">
            <Row className="justify-content-center">
              <Col
                className={
                  docButton == "first" ? "docsSteps-active" : "docsSteps"
                }
              >
                <Row className="d-flex align-items-start mt-3 mb-2">
                  <Col md={12}>
                    <i className="bi bi-hand-index flipIcon"></i>
                  </Col>
                  <Col md={12}>
                    <p>1- Selecciona el tipo de trámite </p>
                  </Col>
                </Row>
              </Col>
              <Col
                className={
                  docButton == "second" ? "docsSteps-active" : "docsSteps"
                }
              >
                <Row className="d-flex align-items-start mt-3 mb-2">
                  <Col md={12}>
                    <i className="bi bi-pass"></i>
                  </Col>
                  <Col md={12}>
                    <p>2- Elige el documento</p>
                  </Col>
                </Row>
              </Col>
              <Col
                className={
                  docButton == "third" ? "docsSteps-active" : "docsSteps"
                }
              >
                <Row className="d-flex align-items-start mt-3 mb-2">
                  <Col md={12}>
                    <i className="bi bi-clipboard2-check"></i>
                  </Col>
                  <Col md={12}>
                    <p>3- Completa el formulario </p>
                  </Col>
                </Row>
              </Col>
              <Col
                className={
                  docButton == "fourth" ? "docsSteps-active" : "docsSteps"
                }
              >
                <Row className="d-flex align-items-start mt-3 mb-2">
                  <Col md={12}>
                    <i className="bi bi-currency-dollar"></i>
                  </Col>
                  <Col md={12}>
                    <p>4- Realiza el pago</p>
                  </Col>
                </Row>
              </Col>
              <Col
                className={
                  docButton == "fifth" ? "docsSteps-active" : "docsSteps"
                }
              >
                <Row className="d-flex align-items-start mt-3 mb-2">
                  <Col md={12}>
                    <i className="bi bi-arrow-down-circle"></i>
                  </Col>
                  <Col md={12}>
                    <p>5- Guarda el documento</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col id="docsMainContainer" xs={12} md={10} className="mt-5">
            <div className="d-block">
              <Row
                className={
                  docButton == "first" || docButton == "second"
                    ? "d-block"
                    : "d-none"
                }
              >
                <Col className="text-center mt-4 ">
                  <h4>Selecciona el tipo de trámite que deseas realizar:</h4>
                </Col>
              </Row>
              <Row className="text-center mb-4">
                <span>_____________________________________</span>
              </Row>

              <div
                id="step-first"
                className={
                  docButton == "first" || docButton == "second"
                    ? "d-block"
                    : "d-none"
                }
              >
                <Row className="justify-content-around justify-content-sm-center">
                  <Col
                    xs={12}
                    md={4}
                    className="justify-content-center text-center"
                  >
                    <Row>
                      <Button
                        className="button third-color docsButton"
                        onClick={() => {
                          setPredialDocs(!predialDocs);
                          setDocButton("second");
                        }}
                      >
                        SOLICITUDES IMPUESTO PREDIAL
                      </Button>
                    </Row>
                    <div className={predialDocs ? "d-none" : "d-block"}>
                      <Row className="justify-content-center">
                        <Col className="docItem" md={10}>
                          <Row className="mb-3 mt-3">
                            <Col md={4}>
                              <Image
                                className="docItem-image"
                                src={docImgade}
                                fluid
                              ></Image>
                            </Col>
                            <Col md={8}>
                              <Row>
                                <h5>
                                  1. Solicitud de copias – Impuesto predial.
                                </h5>
                              </Row>
                              <Row className="text-justify"></Row>
                              <Row className="d-flex align-items-center justify-content-center mt-4">
                                <Col>$8.990</Col>
                                <Col>
                                  <Button
                                    className="fourth-color"
                                    onClick={() =>
                                      handleDocCardClick(
                                        "P_doc1",
                                        "Solicitud de copias – Impuesto predial.",
                                        8990,
                                        Docs_Descriptions.first.long
                                      )
                                    }
                                  >
                                    Seleccionar
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                        <Col className="docItem" md={10}>
                          <Row className="mb-3 mt-3">
                            <Col md={4}>
                              <Image
                                className="docItem-image"
                                src={docImgade}
                              ></Image>
                            </Col>
                            <Col md={8}>
                              <Row>
                                <h5>
                                  2. Solicitud de prescripción impuesto predial
                                  por indebida notificación del mandamiento de
                                  pago.
                                </h5>
                              </Row>
                              <Row></Row>
                              <Row className="d-flex align-items-center justify-content-center">
                                <Col>$28.990</Col>
                                <Col>
                                  <Button
                                    className="fourth-color"
                                    onClick={() =>
                                      handleDocCardClick(
                                        "P_doc2",
                                        "Solicitud de prescripción impuesto predial por indebida notificación del mandamiento de pago.",
                                        28990,
                                        Docs_Descriptions.second.long
                                      )
                                    }
                                  >
                                    Seleccionar
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="docItem" md={10}>
                          <Row className="mb-3 mt-3">
                            <Col md={4}>
                              <Image
                                className="docItem-image"
                                src={docImgade}
                              ></Image>
                            </Col>
                            <Col md={8}>
                              <Row>
                                <h5>
                                  3. Solicitud de prescripción impuesto predial
                                  SIN copias.
                                </h5>
                              </Row>
                              <Row></Row>
                              <Row className="d-flex align-items-center justify-content-center">
                                <Col>$24.950</Col>
                                <Col>
                                  <Button
                                    className="fourth-color"
                                    onClick={() =>
                                      handleDocCardClick(
                                        "P_doc3",
                                        "Solicitud de prescripción impuesto predial SIN copias.",
                                        24950,
                                        Docs_Descriptions.third.long
                                      )
                                    }
                                  >
                                    Seleccionar
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="docItem" md={10}>
                          <Row className="mb-3 mt-3">
                            <Col md={4}>
                              <Image
                                className="docItem-image"
                                src={docImgade}
                                fluid
                              ></Image>
                            </Col>
                            <Col md={8}>
                              <Row>
                                <h5>
                                  4. Acción de tutela por derecho de petición.
                                </h5>
                              </Row>
                              <Row></Row>
                              <Row className="d-flex align-items-center justify-content-center">
                                <Col>$25.600</Col>
                                <Col>
                                  <Button
                                    className="fourth-color"
                                    onClick={() =>
                                      handleDocCardClick(
                                        "I_doc2",
                                        "Acción de tutela por derecho de petición",
                                        25600,
                                        Docs_Descriptions.eighth.long
                                      )
                                    }
                                  >
                                    Seleccionar
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col xs={12} md={4} className="text-center">
                    <Row>
                      <Button
                        className="third-color docsButton"
                        onClick={() => {
                          setTransitoDocs(!transitoDocs);
                          setDocButton("second");
                        }}
                      >
                        SOLICITUDES DE TRÁNSITO
                      </Button>
                    </Row>
                    <div className={transitoDocs ? "d-none" : "d-block"}>
                      <Row className="justify-content-center ">
                        <Col className="docItem" md={10}>
                          <Row className="mb-3 mt-3">
                            <Col md={4}>
                              <Image
                                className="docItem-image"
                                src={docImgade}
                                fluid
                              ></Image>
                            </Col>
                            <Col md={8}>
                              <Row>
                                <h5>
                                  1. Solicitud de copias – Sanción de tránsito
                                </h5>
                              </Row>
                              <Row></Row>
                              <Row className="d-flex align-items-center justify-content-center">
                                <Col>$8.990</Col>
                                <Col>
                                  <Button
                                    className="fourth-color"
                                    onClick={() =>
                                      handleDocCardClick(
                                        "T_doc1",
                                        "Solicitud de copias – Sanción de tránsito",
                                        8990,
                                        Docs_Descriptions.first.long
                                      )
                                    }
                                  >
                                    Seleccionar
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="docItem" md={10}>
                          <Row className="mb-3 mt-3">
                            <Col md={4}>
                              <Image
                                className="docItem-image"
                                src={docImgade}
                                fluid
                              ></Image>
                            </Col>
                            <Col md={8}>
                              <Row>
                                <h5>
                                  2. Solicitud de copias – Impuesto vehicular
                                </h5>
                              </Row>
                              <Row></Row>
                              <Row className="d-flex align-items-center justify-content-center">
                                <Col>$8.990</Col>
                                <Col>
                                  <Button
                                    className="fourth-color"
                                    onClick={() =>
                                      handleDocCardClick(
                                        "T_doc2",
                                        "Solicitud de copias – Impuesto vehicularo",
                                        8990,
                                        Docs_Descriptions.first.long
                                      )
                                    }
                                  >
                                    Seleccionar
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="docItem" md={10}>
                          <Row className="mb-3 mt-3">
                            <Col md={4}>
                              <Image
                                className="docItem-image"
                                src={docImgade}
                                fluid
                              ></Image>
                            </Col>
                            <Col md={8}>
                              <Row>
                                <h5>
                                  3. Solicitud de prescripción sanción de
                                  tránsito por indebida notificación del
                                  mandamiento de pago.
                                </h5>
                              </Row>
                              <Row></Row>
                              <Row className="d-flex align-items-center justify-content-center">
                                <Col>$28.990</Col>
                                <Col>
                                  <Button
                                    className="fourth-color"
                                    onClick={() =>
                                      handleDocCardClick(
                                        "T_doc3",
                                        "Solicitud de prescripción sanción de tránsito por indebida notificación del mandamiento de pago.",
                                        28990,
                                        Docs_Descriptions.fourth.long
                                      )
                                    }
                                  >
                                    Seleccionar
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="docItem" md={10}>
                          <Row className="mb-3 mt-3">
                            <Col md={4}>
                              <Image
                                className="docItem-image"
                                src={docImgade}
                                fluid
                              ></Image>
                            </Col>
                            <Col md={8}>
                              <Row>
                                <h5>
                                  4. Solicitud de prescripción sanción de
                                  tránsito SIN copias.
                                </h5>
                              </Row>
                              <Row></Row>
                              <Row className="d-flex align-items-center justify-content-center">
                                <Col>$26.950</Col>
                                <Col>
                                  <Button
                                    className="fourth-color"
                                    onClick={() =>
                                      handleDocCardClick(
                                        "T_doc4",
                                        "Solicitud de prescripción sanción de tránsito SIN copias.",
                                        26950,
                                        Docs_Descriptions.fifth.long
                                      )
                                    }
                                  >
                                    Seleccionar
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="docItem" md={10}>
                          <Row className="mb-3 mt-3">
                            <Col md={4}>
                              <Image
                                className="docItem-image"
                                src={docImgade}
                                fluid
                              ></Image>
                            </Col>
                            <Col md={8}>
                              <Row>
                                <h5>
                                  5. Solicitud de prescripción impuesto
                                  vehicular.
                                </h5>
                              </Row>
                              <Row></Row>
                              <Row className="d-flex align-items-center justify-content-center">
                                <Col>$8.990</Col>
                                <Col>
                                  <Button
                                    className="fourth-color"
                                    onClick={() =>
                                      handleDocCardClick(
                                        "T_doc5",
                                        "Solicitud de prescripción impuesto vehicular.",
                                        8990,
                                        Docs_Descriptions.seventh.long
                                      )
                                    }
                                  >
                                    Seleccionar
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                        <Col className="docItem" md={10}>
                          <Row className="mb-3 mt-3">
                            <Col md={4}>
                              <Image
                                className="docItem-image"
                                src={docImgade}
                                fluid
                              ></Image>
                            </Col>
                            <Col md={8}>
                              <Row>
                                <h5>
                                  6. Solicitud de prescripción sanción de
                                  tránsito SIN copias y SIN mandamiento de pago.
                                </h5>
                              </Row>
                              <Row></Row>
                              <Row className="d-flex align-items-center justify-content-center">
                                <Col>$24.950</Col>
                                <Col>
                                  <Button
                                    className="fourth-color"
                                    onClick={() =>
                                      handleDocCardClick(
                                        "T_doc6",
                                        "Solicitud de prescripción sanción de tránsito SIN copias y SIN mandamiento de pago.",
                                        24950,
                                        Docs_Descriptions.first.long
                                      )
                                    }
                                  >
                                    Seleccionar
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="docItem" md={10}>
                          <Row className="mb-3 mt-3">
                            <Col md={4}>
                              <Image
                                className="docItem-image"
                                src={docImgade}
                                fluid
                              ></Image>
                            </Col>
                            <Col md={8}>
                              <Row>
                                <h5>
                                  7. Acción de tutela por derecho de petición.
                                </h5>
                              </Row>
                              <Row></Row>
                              <Row className="d-flex align-items-center justify-content-center">
                                <Col>$25.600</Col>
                                <Col>
                                  <Button
                                    className="fourth-color"
                                    onClick={() =>
                                      handleDocCardClick(
                                        "I_doc2",
                                        "Acción de tutela por derecho de petición",
                                        25600,
                                        Docs_Descriptions.eighth.long
                                      )
                                    }
                                  >
                                    Seleccionar
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  <Col xs={12} md={4} className="text-center">
                    <Row>
                      <Button
                        className="third-color docsButton"
                        onClick={() => {
                          setIndustriaDocs(!industriaDocs);
                          setDocButton("second");
                        }}
                      >
                        SOLICITUDES INDUSTRIA Y COMERCIO
                      </Button>
                    </Row>
                    <div className={industriaDocs ? "d-none" : "d-block"}>
                      <Row className="justify-content-center">
                        <Col className="docItem" md={10}>
                          <Row className="mb-3 mt-3">
                            <Col md={4}>
                              <Image
                                className="docItem-image"
                                src={docImgade}
                                fluid
                              ></Image>
                            </Col>
                            <Col md={8}>
                              <Row>
                                <h5>
                                  1. Solicitud de copias – Impuesto de industria
                                  y comercio.
                                </h5>
                              </Row>
                              <Row></Row>
                              <Row className="d-flex align-items-center justify-content-center">
                                <Col>$8.990</Col>
                                <Col>
                                  <Button
                                    className="fourth-color"
                                    onClick={() =>
                                      handleDocCardClick(
                                        "I_doc1",
                                        "Solicitud de copias – Impuesto de industria y comercio.",
                                        8990,
                                        Docs_Descriptions.first.long
                                      )
                                    }
                                  >
                                    Seleccionar
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="docItem" md={10}>
                          <Row className="mb-3 mt-3">
                            <Col md={4}>
                              <Image
                                className="docItem-image"
                                src={docImgade}
                                fluid
                              ></Image>
                            </Col>
                            <Col md={8}>
                              <Row>
                                <h5>
                                  2. Acción de tutela por derecho de petición.
                                </h5>
                              </Row>
                              <Row></Row>
                              <Row className="d-flex align-items-center justify-content-center">
                                <Col>$25.600</Col>
                                <Col>
                                  <Button
                                    className="fourth-color"
                                    onClick={() =>
                                      handleDocCardClick(
                                        "I_doc2",
                                        "Acción de tutela por derecho de petición",
                                        25600,
                                        Docs_Descriptions.eighth.long
                                      )
                                    }
                                  >
                                    Seleccionar
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            <div
              id="step-third"
              className={docButton !== "third" ? "d-none" : "d-block"}
            >
              <Row className="mt-5">
                <Col>
                  {/*
								<DocumentsForm
									goback={setDocButton} />
*/}

                  <DocsF
                    goback={setDocButton}
                    currentDoc={currentDoc}
                    currentDocName={currenDocName}
                    indexHandler={setIndex}
                    index={index}
                    desc={currentDocDesc}
                  />
                </Col>
              </Row>
            </div>

            <div
              id="step-fourth"
              className={docButton !== "fourth" ? "d-none" : "d-block"}
            >
              <Row>
                {" "}
                <i
                  id="backArrowDocs"
                  onClick={() => setDocButton("third")}
                  className="bi bi-arrow-left-circle-fill"
                ></i>
              </Row>

              <Row className="d-flex justify-content-center align-items-center">
                <Col md={6} className="text-center">
                  <Alert
                    show={alert}
                    variant="success"
                    onClose={() => setAlert(false)}
                    dismissible
                  >
                    <Alert.Heading>Pago realizado exitosamente</Alert.Heading>
                  </Alert>
                </Col>
                <OrderFormTuCompra handle_payment={handlePayment} />
              </Row>
            </div>

            <div
              id="step-fifth"
              className={docButton !== "fifth" ? "d-none" : "d-block"}
            >
              <Row>
                {" "}
                <i
                  id="backArrowDocs"
                  onClick={() => setDocButton("first")}
                  className="bi bi-arrow-left-circle-fill"
                ></i>
              </Row>

              <Row className="d-flex justify-content-center align-items-center">
                <Col md={3} className="text-center">
                  <Alert
                    show={docDownloadedAlert}
                    variant="success"
                    onClose={() => setAlert(false)}
                    dismissible
                  >
                    <Alert.Heading>
                      Documento descargado exitosamente !
                    </Alert.Heading>
                  </Alert>
                  <p>
                    {" "}
                    Muchas gracias por confiar en nosotros para tus tramites
                    legales, acabas de descargar el documento que elegiste,
                    recuerda presentarlo en las fechas que estipulaste. Si
                    necesitas más asesoría no dudes en contactarnos:{" "}
                    <a
                      className="wpp_text_link"
                      target="_blank"
                      rel="noreferrer"
                      href="https://api.whatsapp.com/send?phone=3128013791&text=Tengo%20una%20duda%20sobre:"
                    >
                      Practidocumentos
                    </a>
                  </p>
                  {/*<Button className='third-color' disabled={showDownloadButton} onClick={handleDownloadClick}>Descargar <i className="bi bi-arrow-down-circle"></i></Button>*/}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>

      {/*screenSize.width >= 992 ? <DocsDesktopCarousel /> : "nothing" ||
				screenSize.width < 992 && screenSize.width >= 768 ? <DocsTabletCarousel /> : ""*/}
    </div>
  );
};

export default MainDocuments;
