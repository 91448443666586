import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import aboutBanner from '../images/mainabout.jpg';




const MainAboutus = () => {
    return (

        <div id="mainAbout" >
            <Container fluid >
                <Row className='d-flex align-items-top justify-content-center'>
                    <Col xs={12} md={4} className=' p-5 text-center text-light' >
                        <h2 className="text-center text-secondaryColor">  ¿ Quienes somos ? </h2>
                        <div className="line"></div>
                    </Col>
                </Row>

                <Row className=' d-flex align-items-top justify-content-center'>
                    <Col id="" md={10}>
                      <Col id="about_image_container" className="paralaxbg" md={12} style={{ backgroundImage: `url(${aboutBanner})` }}>
                        </Col>
                    </Col>
                </Row>

                <Row className="d-flex align-items-top justify-content-center " >
                    <Col xs={10} md={6} id="aboutText" className="mt-5 mb-5">
                        <Row className='d-flex h-100 align-items-center justify-content-center text-align-justify'>
                            <Col xs={12} md={10} className=''>
                                <p>
                                    Somos un equipo de abogados expertos en derecho tributario y sancionatorio,
                                    disponibles para proporcionarte herramientas legales efectivas y de alta calidad
                                    que permitan abordar tus asuntos legales con confianza, practicidad y economía.
                                    Contáctate con nosotros a través de Whatsapp.
                                </p>
                            </Col>
                            
                        </Row>

                    </Col>
                </Row>
            </Container>
        </div>

    );


}

export default MainAboutus;