import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";
import Logo from "../images/practiDocsLogo.png";
import LogoText from "../images/practiDocsTextLogo.png";
import Modal_order_confirmation from "../components/order_confirmation_modal";
import Modal_order_error_handler from "../components/order_error_handler_modal";
import secureLocalStorage from "react-secure-storage";

const Order_Response = () => {
  const [orderData, setOrderData] = useState({});

  const [showDownload, setShowDownload] = useState(false);

  const [paymentError, setPaymentError] = useState(false);

  const [documentName, setDocumentName] = useState("");

  const [dataSaved, setDataSaved] = useState({
    db: false,
    doc: false,
    email: false,
  });

  const [downloadDoc, setDownloadDoc] = useState(false);

  const [confirmationModal, setConfirmationModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);

  const getOrderResponse = async () => {
    let localData = localStorage.getItem("orderData");

    if (localData !== null) {
      localData = JSON.parse(localData);
      setOrderData(localData);

      if (localData.state === "Transacción aprobada") {
        generateDoc();
        createWord_document();
        sendToDB();
        sendEmail();
        setConfirmationModal(true);
      } else {
        handlePaymentError(localData.state);
        sendToDB();
      }
    } else {
      //console.log("no hay información relacionada a la orden");
    }
  };

  useEffect(() => {
    getOrderResponse();
  }, []);

  useEffect(() => {
    if (dataSaved.db && dataSaved.doc && dataSaved.email && dataSaved.word) {
      secureLocalStorage.clear();
    }
  }, [dataSaved]);

  const handlePaymentError = (state) => {
    secureLocalStorage.setItem("paymentError", state);
    setPaymentError(true);
  };

  const sendToDB = async () => {
    //De esta aun no se ve nada Pendiente revisar
    let orderForm = JSON.parse(secureLocalStorage.getItem("order"));
    //de esta variable la que trae todas las respuestas del doc es answers
    let docForm = JSON.parse(secureLocalStorage.getItem("docFormData"));
    // de esta variable el id
    let orderResponse = JSON.parse(localStorage.getItem("orderData"));
    //confiramado funciona y trae el nombre del documento.
    let docName = secureLocalStorage.getItem("docName");

    //Datos listos para ser guardados en al DB READY
    let userDataDb = {
      id: orderForm.documentoComprador,
      idType: orderForm.tipoDocumento,
      name: orderForm.nombreComprador,
      lastName: orderForm.apellidoComprador,
      email: orderForm.correoComprador,
      phone: orderForm.celularComprador,
    };

    let orderDataDb = {
      creationDate: showDate(),
      referenceCode: orderResponse.codigoAutorizacion,
      paymentCode: orderResponse.codigoFactura,
      cost: orderForm.amount,
      state: orderResponse.state,
    };

    let docDataDb = {
      docName:
        orderForm.nombreComprador +
        "-" +
        orderForm.apellidoComprador +
        "-" +
        docForm.documentName,
      documentType: docForm.documentType,
      creatioDate: showDate(),
      formData: JSON.stringify(docForm.answers),
    };

    let data = {
      user: userDataDb,
      order: orderDataDb,
      doc: docDataDb,
      type: "save",
    };

    const webUrl =
      "https://practidocumentos.com/backend/controllers/orderController.php";
    const localURL =
      "http://localhost/practidocs/backend/controllers/orderController.php";
    const res = await axios.post(webUrl, data, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
      },
    });

    // console.log(res.data);
    if (res.data === "SUCCESS") {
      setDataSaved({
        db: true,
      });
    } else {
      //console.log(res);
    }
    //setDocumentName(docForm.documentName);
  };

  const generateDoc = async () => {
    try {
      let data = JSON.parse(secureLocalStorage.getItem("docFormData"));
      let orderInfo = JSON.parse(secureLocalStorage.getItem("order"));
      if (orderInfo.userName !== undefined || orderInfo.userName !== "") {
      }
      let docN =
        orderInfo.nombreComprador +
        "_" +
        orderInfo.apellidoComprador +
        "_" +
        data.documentName;
      let docName = docN ? docN : "Practidocumentos";

      data.userEmail = orderInfo.correoComprador;
      data.rType = "doc";
      data.main = "doc";
      data.docName = docName;
      // props.currentDoc_formData(data);
      // console.log(data);
      const webUrl =
        "https://practidocumentos.com/backend/documents/docsController.php";
      const localURL =
        "http://localhost/practidocs/backend/documents/docsController.php";
      const res = await axios.post(webUrl, data, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
        },
      });
      const url = window.URL.createObjectURL(new Blob([res.data]));
      let docFinalname = docName + ".pdf";
      secureLocalStorage.setItem("docUrl", url);
      secureLocalStorage.setItem("docName", docFinalname);
      const link = document.createElement("a");
      link.href = url;
      //link.setAttribute('download', docFinalname);
      //document.body.appendChild(link);
      link.download = docFinalname;
      link.dispatchEvent(new MouseEvent("click"));
      // setDownloadURl(url);
      //updateData(url);
      //props.goback('third');
      setDataSaved({
        doc: true,
      });
    } catch (err) {
      //console.log("error in document url " + err);
    }
  };
  const createWord_document = async () => {
    try {
      let data = JSON.parse(secureLocalStorage.getItem("docFormData"));
      let orderInfo = JSON.parse(secureLocalStorage.getItem("order"));
      let docN =
        orderInfo.nombreComprador +
        "_" +
        orderInfo.apellidoComprador +
        "_" +
        data.documentName;
      let docName = docN ? docN : "Practidocumentos";

      data.userEmail = orderInfo.correoComprador;
      data.rType = "word";
      data.main = "doc";
      data.docName = docName;
      // props.currentDoc_formData(data);
      // console.log(data);
      const webUrl =
        "https://practidocumentos.com/backend/documents/docsController.php";
      const localURL =
        "http://localhost/practidocs/backend/documents/docsController.php";
      const res = await axios.post(webUrl, data, {
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
        },
      });

      download_word(res.data[0], res.data[1]);
    } catch (err) {
      //console.log("error in document url " + err);
    }
  };
  const download_word = async (filename, html) => {
    let preHtml =
      "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w=urn:schemas-microsoft-com:office:office";
    let postHtml = "</body></html>";
    let finalHtml = preHtml + html;

    let blob = new Blob(["\ufeff", html], {
      type: "application/msword",
    });

    let formData = new FormData();
    formData.append("file", blob, filename + ".doc");
    formData.append("type", "word_save");
    const webUrl =
      "https://practidocumentos.com/backend/documents/saveWordDoc.php";

    const res = await axios.post(webUrl, formData);

    //console.log(res);

    let url =
      "data:application/vnd.ms-word;charset=utf-8," +
      encodeURIComponent(finalHtml);

    filename = filename + ".doc";
    let downloadlink = document.createElement("a");
    downloadlink.href = url;
    downloadlink.download = filename;
    downloadlink.click();
    setDataSaved({
      word: true,
    });
  };

  const sendEmail = async () => {
    try {
      let data = JSON.parse(secureLocalStorage.getItem("docFormData"));
      let orderInfo = JSON.parse(secureLocalStorage.getItem("order"));
      let docN =
        orderInfo.nombreComprador +
        "_" +
        orderInfo.apellidoComprador +
        "_" +
        data.documentName;
      let docName = docN ? docN : "Practidocumentos";

      let dataToSend = {
        main: "mail",
        docName: docName,
        userEmail: orderInfo.correoComprador,
      };

      const webUrl =
        "https://practidocumentos.com/backend/documents/docsController.php";
      const localURL =
        "http://localhost/practidocs/backend/documents/docsController.php";
      const res = await axios.post(webUrl, dataToSend, {
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
        },
      });
      //console.warn("EMAIL: " + JSON.stringify(res.data));
      setDataSaved({
        email: true,
      });
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log("ERROR FROM SERVER");
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log("REQUEST NO RESPONSE");
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        console.log("ERROR IN THE REQUEST");
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    }
  };

  const handleClick = () => {
    // sendToDB();
    //secureLocalStorage.clear();
    window.open("https://practidocumentos.com/#mainDocuments", "_self");
    //window.open('http://localhost:3000/#mainDocuments', '_self')
  };

  const handleDocDownload = () => {
    generateDoc();
    createWord_document();
    setDownloadDoc(true);
  };

  const generateUniqueCode = () => {
    // Generate a timestamp to make the code partially time-based
    const timestamp = Date.now().toString(36);
    // Generate a random number or string to add randomness
    const randomPart = Math.random().toString(36).slice(2);
    // Combine the timestamp and random part to create a unique code
    const code = timestamp + randomPart;
    return code;
  };

  const showDate = () => {
    let today = new Date();
    let date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    let time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    let date_time = date + " " + time;
    return date_time;
  };

  const handleDownload = async () => {
    let docName = "Practidocumentos_comprobante" + ".pdf";
    let data = {
      documentType: "orderRecipe",
      documentName: docName,
      answers: orderData,
    };
    const webUrl =
      "https://practidocumentos.com/backend/documents/docsController.php";
    const localURL =
      "http://localhost/practidocs/backend/documents/docsController.php";
    const res = await axios.post(webUrl, data, {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
      },
    });
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", docName);
    document.body.appendChild(link);
    link.click();
    setShowDownload(true);
  };

  return (
    <Container fluid={true} className="fullH orderResponse_container paralaxbg">
      <div>
        {confirmationModal && (
          <Modal_order_confirmation confirmationModal={setConfirmationModal} />
        )}
      </div>
      <div>
        {paymentError && (
          <Modal_order_error_handler
            paymentError={setPaymentError}
            type={secureLocalStorage.getItem("paymentError")}
          />
        )}
      </div>
      <Navbar variant="dark" expand="lg" fixed="top" className="mainColor">
        <Container>
          <Navbar.Brand>
            <Image className="navLogo" src={Logo}></Image>
            <Image className="navLogoText" src={LogoText}></Image>
          </Navbar.Brand>
        </Container>
      </Navbar>
      {!paymentError && (
        <Row className="text-center d-flex justify-content-center align-items-center mt-4">
          <Col xs={12} md={6} className="response_tableContainer p-2 mt-5 mb-2">
            <p>
              Si el documento no se descargo de manera automatica por favor
              descargarlo con el siguiente botón:
            </p>
            <Button disabled={downloadDoc} onClick={handleDocDownload}>
              Descargar Documento
            </Button>
          </Col>
        </Row>
      )}
      <Row className="text-center d-flex justify-content-center align-items-center mt-5">
        <Col md={6} className="response_tableContainer">
          <Table striped bordered size="lg">
            <thead>
              <tr>
                <th>Estado</th>
                <th>{orderData.state}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Referencia de la transacción</td>
                <td>{orderData.codigoFactura}</td>
              </tr>

              <tr>
                <td>Codigo de Autorización</td>
                <td>{orderData.codigoAutorizacion}</td>
              </tr>
              <tr>
                <td>Metodo de Pago</td>
                <td>{orderData.metodoPago}</td>
              </tr>
              <tr>
                <td>Valor</td>
                <td>{orderData.amount}</td>
              </tr>
              <tr>
                <td>Descripción</td>
                <td>
                  {documentName ? documentName : "Adquisición de documento"}
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row className="text-center d-flex justify-content-center align-items-center mt-4">
        <Col xs={6} md={2}>
          <Button onClick={handleClick}>Confirmar</Button>
        </Col>
        {!paymentError && (
          <Col xs={6} md={2}>
            <Button onClick={handleDownload} disabled={showDownload}>
              Descargar recibo
            </Button>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Order_Response;
