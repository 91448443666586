const Docs_Descriptions = {
    first: {
        short: 'Antes de realizar cualquier solicitud de prescripción o iniciar un proceso por el cobro coactivo desarrollado en tu contra, debes conocer el contenido del expediente. Por ello, es importante iniciar con una solicitud de copias de los documentos que componen dicho proceso.',
        long: '<div><h5>Descripción del documento</h5><p>Antes de realizar cualquier solicitud de prescripción o iniciar un proceso por el cobro coactivo desarrollado en tu contra, debes conocer el contenido del expediente. Por ello, es importante iniciar con una solicitud de copias de los documentos que componen dicho proceso. </p><p>Este documento debe ser diligenciado y presentado por el ciudadano titular de la obligación o del proceso de cobro coactivo. En caso de contar con apoderado (abogado), debe adjuntar la copia del poder autenticado que le delegue la facultad de presentar solicitudes de copias a su nombre.</p><p>Si, por el contrario, la solicitud la realizas a nombre propio, debes remitirla desde el correo personal del titular del proceso, o debe autenticarse para radicarla en físico. Lo anterior, considerando que las Entidades públicas tienen la obligación de garantizar la reserva legal de los documentos de cobro.</p><p><strong>¡IMPORTANTE!</strong> Debes contar con un correo electrónico en el que puedas recibir la respuesta con las copias digitales. Esto, con el fin de agilizar tu proceso y no generar gastos adicionales de copias físicas.</p><p><strong>¡RECUERDA!</strong>  Contáctanos si requieres la revisión de tu expediente y asesoría profesional en el proceso. </p><p>Contactanos a través de whatsapp: <a className="wpp_text_link" target="_blank" href="https://api.whatsapp.com/send?phone=3225921651&text=Me%20interesa%20saber%20sobre%20las%20asesor%C3%ADas%20legales%20respecto%20a:">Contactar</a></p></div>'
    },
    second: {
        short: 'La notificación del Mandamiento de pago es la vida del proceso de cobro coactivo. Por lo cual, en caso de que la Entidad no realice el proceso de notificación establecido por la ley, es procedente solicitar la prescripción de la acción de cobro al superar los cinco años del inicio de la obligación',
        long: '<div><h5>Descripción del documento</h5><p>La notificación del Mandamiento de pago es la vida del proceso de cobro coactivo. Por lo cual, en caso de que la Entidad no realice el proceso de notificación establecido por la ley, es procedente solicitar la prescripción de la acción de cobro al superar los cinco años del inicio de la obligación.</p><p>Siendo así, es fundamental conocer todas las actuaciones administrativas que se han desarrollado en el proceso de cobro coactivo, lo cual lo conocerás a través de la solicitud de copias disponible en esta misma página web.</p><p>Una vez obtenidas las copias del proceso, analizarás si existe notificación personal del mandamiento de pago o si se agotaron las notificaciones subsidiarias establecidas en la normatividad nacional vigente; siendo las siguientes:</p><ol><li>Citación dirigida al contribuyente.</li><li>Notificación por medio de correo certificado (en caso de no asistencia a la citación).</li><li>Notificación por aviso, compuesta por fijación en cartelera y publicación en la página web de la Entidad (en caso de devolución del correo certificado).</li></ol><p>Al identificar que la Entidad no cumplió con el debido proceso de la notificación del mandamiento de pago, podrás realizar esta solicitud de prescripción de la acción de cobro por indebida notificación.</p><p><strong>¡RECUERDA!</strong> Contáctanos si requieres la revisión de tu expediente y asesoría profesional en el proceso.</p><p>Contactanos a través de whatsapp: <a className="wpp_text_link" target="_blank" href="https://api.whatsapp.com/send?phone=3225921651&text=Me%20interesa%20saber%20sobre%20las%20asesor%C3%ADas%20legales%20respecto%20a:">Contactar</a></p></div>'
    },
    third: {
        short: 'Esta solicitud procede cuando la Entidad pública expide la factura del Impuesto predial unificado y en ella se observa el cobro de más de cinco años o vigencias.',
        long: '<div><h5>Descripción del documento</h5><p>Esta solicitud procede cuando la Entidad pública expide la factura del Impuesto predial unificado y en ella se observa el cobro de más de cinco años o vigencias.</p><strong>¡IMPORTANTE!</strong> Para realizar la solicitud, el peticionario necesitará la factura del impuesto predial unificado.<p>Así mismo, en caso de que el contribuyente conozca que se adelanta un proceso de cobro coactivo en su contra, se sugiere solicitar copias del expediente para iniciar un proceso mucho más completo.</p><p><strong>¡RECUERDA!</strong> Contáctanos si requieres la revisión de tu expediente y asesoría profesional en el proceso.</p><p>Contactanos a través de whatsapp: <a className="wpp_text_link" target="_blank" href="https://api.whatsapp.com/send?phone=3225921651&text=Me%20interesa%20saber%20sobre%20las%20asesor%C3%ADas%20legales%20respecto%20a:">Contactar</a></p></div>'
    },
    fourth: {
        short: 'Esta solicitud procede cuando la sanción de tránsito fue impuesta hace más de tres (3) años y durante ese periodo la entidad no realizó el proceso de notificación establecido por la ley.',
        long: '<div><h5>Descripción del documento</h5><p>Esta solicitud procede cuando la sanción de tránsito fue impuesta hace más de tres (3) años y durante ese periodo la entidad no realizó el proceso de notificación establecido por la ley.</p><p>Siendo así, es fundamental conocer todas las actuaciones administrativas que se han desarrollado en el proceso de cobro coactivo, lo cual lo conocerás a través de la solicitud de copias disponible en esta misma página web.</p><p>Una vez obtenidas las copias del proceso, analizarás si existe notificación personal del mandamiento de pago o si se agotaron las notificaciones subsidiarias establecidas en la normatividad nacional vigente; siendo las siguientes:</p><ol><li>Citación dirigida al ciudadano.</li><li>Notificación por medio de correo certificado (en caso de no asistencia a la citación).</li><li> Notificación por aviso, compuesta por fijación en cartelera y publicación en la página web de la Entidad (en caso de devolución del correo certificado).</li></ol><p>Al identificar que la Entidad no cumplió con el debido proceso de la notificación del mandamiento de pago, podrás realizar esta solicitud de prescripción de la acción de cobro por indebida notificación.</p><p><strong>¡RECUERDA!</strong> Contáctanos si requieres la revisión de tu expediente y asesoría profesional en el proceso.</p><p>Contactanos a través de whatsapp: <a className="wpp_text_link" target="_blank" href="https://api.whatsapp.com/send?phone=3225921651&text=Me%20interesa%20saber%20sobre%20las%20asesor%C3%ADas%20legales%20respecto%20a:">Contactar</a></p></div>'
    },
    fifth: {
        short: 'Esta solicitud procede cuando la sanción de tránsito fue realizada hace más de tres (3) años y se desconoce de proceso de cobro coactivo en contra del sancionado, considerando que nunca se le ha notificado un acto administrativo o algún oficio que referencie la sanción. ',
        long: '<div><h5>Descripción del documento</h5><p>Esta solicitud procede cuando la sanción de tránsito fue realizada hace más de tres (3) años y se desconoce de proceso de cobro coactivo en contra del sancionado, considerando que nunca se le ha notificado un acto administrativo o algún oficio que referencie la sanción.</p><p>Sin embargo, el sancionado ha observado el inicio del cobro coactivo en el sistema integrado de información sobre multas y sanciones por infracciones de tránsito (SIMIT), plataforma a la que se puede acceder a través del siguiente link <a target="_blank" href="https://fcm.org.co/simit/#/home-public">Simit</a></p><p><strong>¡RECUERDA!</strong> Contáctanos si requieres la revisión de tu expediente y asesoría profesional en el proceso.</p><p>Contactanos a través de whatsapp: <a className="wpp_text_link" target="_blank" href="https://api.whatsapp.com/send?phone=3225921651&text=Me%20interesa%20saber%20sobre%20las%20asesor%C3%ADas%20legales%20respecto%20a:">Contactar</a></p></div>'
    },
    sixth: {
        short: 'Esta solicitud procede cuando la sanción de tránsito fue realizada hace más de tres (3) años y se desconoce de proceso de cobro coactivo en contra del sancionado, considerando que nunca se le ha notificado un acto administrativo o algún oficio que referencie la sanción. ',
        long: '<div><h5>Descripción del documento</h5><p>Esta solicitud procede cuando la sanción de tránsito fue realizada hace más de tres (3) años y se desconoce de proceso de cobro coactivo en contra del sancionado, considerando que nunca se le ha notificado un acto administrativo o algún oficio que referencie la sanción.</p><p>Así mismo, no se evidencia inicio del proceso de cobro en el sistema integrado de información sobre multas y sanciones por infracciones de tránsito (SIMIT), plataforma a la que se puede acceder a través del siguiente link <a target="_blank" href="https://fcm.org.co/simit/#/home-public">Simit</a></p><p><strong>¡RECUERDA!</strong> Contáctanos si requieres la revisión de tu expediente y asesoría profesional en el proceso.</p><p>Contactanos a través de whatsapp: <a className="wpp_text_link" target="_blank" href="https://api.whatsapp.com/send?phone=3225921651&text=Me%20interesa%20saber%20sobre%20las%20asesor%C3%ADas%20legales%20respecto%20a:">Contactar</a></p></div>'
    },
    seventh: {
        short: 'Esta solicitud procede cuando el cobro del impuesto vehicular supera los cinco (5) años y se desconoce de proceso de cobro coactivo',
        long: '<div><h5>Descripción del documento</h5><p>Esta solicitud procede cuando el cobro del impuesto vehicular supera los cinco (5) años y se desconoce de proceso de cobro coactivo en contra del contribuyente, considerando que nunca se le ha notificado un acto administrativo o algún oficio que referencie dicha obligación.</p><p><strong>¡RECUERDA!</strong> Contáctanos si requieres la revisión de tu expediente y asesoría profesional en el proceso.</p><p>Contactanos a través de whatsapp: <a className="wpp_text_link" target="_blank" href="https://api.whatsapp.com/send?phone=3225921651&text=Me%20interesa%20saber%20sobre%20las%20asesor%C3%ADas%20legales%20respecto%20a:">Contactar</a></p></div>'
    },
    eighth: {
        short: 'El modelo de esta acción de tutela fue diseñado para ser presentado por el ciudadano al que se le vulneró el derecho fundamental de petición, al evidenciar que la entidad excedió',
        long: '<div><h5>Descripción del documento</h5><p>El modelo de esta acción de tutela fue diseñado para ser presentado por el ciudadano al que se le vulneró el derecho fundamental de petición, al evidenciar que la entidad excedió el término para remitir la respuesta de la solicitud.</p><p>Para presentar la acción en debida forma, el ciudadano debe realizar el conteo de los días hábiles que han transcurrido desde el momento en el que se radicó la petición, para así verificar que proceda la acción. </p><p>Estos son los términos que por ley tienen los funcionarios públicos para responder las solicitudes interpuestas por los ciudadanos:</p><ul><li>Término general:15 días hábiles.</li><li>Solicitud de copias e información: 10 días hábiles.</li><li>Solicitud por medio de la cual se eleva una consulta a funcionario público: 30 días hábiles.</li></ul><p>Si evidencias que los días transcurridos desde la radicación de tu solicitud, superan el término establecido, procede esta acción de tutela.</p><p><strong>¡RECUERDA!</strong> Contáctanos si requieres la revisión de tu expediente y asesoría profesional en el proceso.</p><p>Contactanos a través de whatsapp: <a className="wpp_text_link" target="_blank" href="https://api.whatsapp.com/send?phone=3225921651&text=Me%20interesa%20saber%20sobre%20las%20asesor%C3%ADas%20legales%20respecto%20a:">Contactar</a></p></div>'
    }
}

export default Docs_Descriptions;