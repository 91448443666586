import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import SharedDataOrderContext from "../helpers/sharedOrderContext";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Container } from "react-bootstrap";
import guideImage1 from "../images/purchaseGuide/step1.png";
import { json } from "react-router-dom";
import Modal_order_images from "../components/order_purchaseModal";
import Modal_pay_guide from "./order_payuGuide_model";
import secureLocalStorage from "react-secure-storage";
import tuCompra from "../images/tu_compra_logo.png";

const MainTestPage = () => {
  const [ticketID, setTicket] = useState("");
  const generateUniqueCode = () => {
    // Generate a timestamp to make the code partially time-based
    const timestamp = Date.now().toString(36);
    // Generate a random number or string to add randomness
    const randomPart = Math.random().toString(36).slice(2);
    // Combine the timestamp and random part to create a unique code
    const code = timestamp + randomPart;
    return code;
  };

  const sendToDB = async () => {
    let orderForm = JSON.parse(secureLocalStorage.getItem("order"));
    //de esta variable la que trae todas las respuestas del doc es answers
    let docForm = JSON.parse(secureLocalStorage.getItem("docFormData"));
    // de esta variable el id
    let orderResponse = JSON.parse(localStorage.getItem("orderData"));
    //confiramado funciona y trae el nombre del documento.

    //Datos listos para ser guardados en al DB READY
    let userDataDb = {
      id: orderForm.userId,
      idType: orderForm.userIdType,
      name: orderForm.userName,
      lastName: orderForm.userLastName,
      email: orderForm.userEmail,
      phone: orderForm.userPhone,
    };

    let orderDataDb = {
      creationDate: showDate(),
      referenceCode: orderResponse.codigoAutorizacion,
      paymentCode: orderResponse.codigoFactura,
      cost: orderResponse.amount,
      state: "Pendiente",
    };

    let docDataDb = {
      docName: docForm.documentName,
      creatioDate: showDate(),
      documentType: docForm.documentType,
      formData: JSON.stringify(docForm.answers),
    };

    let data = {
      user: userDataDb,
      order: orderDataDb,
      doc: docDataDb,
      type: "save",
    };

    console.log(data);

    const webUrl =
      "https://practidocumentos.com/backend/controllers/orderController.php";
    const localURL =
      "http://localhost/practidocs/backend/controllers/orderController.php";

    const res = await axios.post(localURL, data, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    console.log("SAVE: " + JSON.stringify(res.data));

    if (res.data === "SUCCESS") {
      console.log("informacion guardada con exito en la base de datos");
    } else {
      console.log(
        "se presento un error al momento de guardar la información" + res.data
      );
    }
  };

  const generateDoc = async () => {
    try {
      let docName = "documentoTEST";

      let docForm = {
        main: "doc",
        documentType: "P_doc2",
        documentName:
          "Solicitud de prescripción impuesto predial por indebida notificación del mandamiento de pago.",
        answers: {
          "1_one": "",
          "1_residenceCity": "Caracolí",
          "5_applicationDate": "2023-12-21",
          "1_officialName": "carlos",
          "1_officialPosition": "secretario",
          "1_entityAddress": "",
          "4_entityEmail": "",
          "1_entityCity": "Cali",
          "1_petitionerName": "Juan Gomez",
          "1_identificationType": "Cédula de ciudadanía",
          "1_identificationNumber": "1212145874",
          "1_petitionerRole": "heredero",
          "1_cadastralNumber": "252541515",
          "1_propertyOwnerName": "Juan Gomez",
          "1_liquidationNumber": "265161563",
          "5_liquidationDate": "2023-12-14",
          "1_liquidationValue": "150000000",
          "1_notificationMethod": "correo electronico",
          "1_paymentOrderNumber": "1458894784",
          "5_paymentOrderDate": "2023-12-20",
          "4_notificationEmail": "juan@gmail.com",
          "1_notificationAddress": "carrera 8 #10-33",
          "1_petitionerPhoneNumber": "3214789658",
        },
      };
      docForm.rType = "doc";
      docForm.userEmail = "juanc7795@gmail.com";
      const webUrl =
        "https://practidocumentos.com/backend/documents/docsController.php";
      const localURL =
        "http://localhost/practidocs/backend/documents/docsController.php";
      const res = await axios.post(webUrl, docForm, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/pdf",
        },
      });
      console.log(res.data);

      const url = window.URL.createObjectURL(new Blob([res.data]));

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", docForm.documentName);
      //document.body.appendChild(link);
      link.download = docForm.documentName + ".pdf";
      link.dispatchEvent(new MouseEvent("click"));
      // setDownloadURl(url);
      //updateData(url);
      //props.goback('third');
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log("ERROR FROM SERVER");
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log("REQUEST NO RESPONSE");
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        console.log("ERROR IN THE REQUEST");
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
    }
  };
  const sendEmail = async () => {
    try {
      let data = {
        main: "mail",
        documentType: "P_doc2",
        documentName:
          "Solicitud de prescripción impuesto predial por indebida notificación del mandamiento de pago.",
        answers: {
          "1_one": "",
          "1_residenceCity": "Caracolí",
          "5_applicationDate": "2023-12-21",
          "1_officialName": "carlos",
          "1_officialPosition": "secretario",
          "1_entityAddress": "",
          "4_entityEmail": "",
          "1_entityCity": "Cali",
          "1_petitionerName": "Juan Gomez",
          "1_identificationType": "Cédula de ciudadanía",
          "1_identificationNumber": "1212145874",
          "1_petitionerRole": "heredero",
          "1_cadastralNumber": "252541515",
          "1_propertyOwnerName": "Juan Gomez",
          "1_liquidationNumber": "265161563",
          "5_liquidationDate": "2023-12-14",
          "1_liquidationValue": "150000000",
          "1_notificationMethod": "correo electronico",
          "1_paymentOrderNumber": "1458894784",
          "5_paymentOrderDate": "2023-12-20",
          "4_notificationEmail": "juan@gmail.com",
          "1_notificationAddress": "carrera 8 #10-33",
          "1_petitionerPhoneNumber": "3214789658",
        },
      };

      let docName = "Practidocumentos";
      data.docName = docName;

      data.userEmail = "juanc7795@gmail.com";
      data.rType = "mail";
      // props.currentDoc_formData(data);
      // console.log(data);
      const webUrl =
        "https://practidocumentos.com/backend/documents/docsController.php";
      const localURL =
        "http://localhost/practidocs/backend/documents/docsController.php";
      const res = await axios.post(webUrl, JSON.stringify(data), {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
        },
      });

      console.log(res);
    } catch (err) {
      console.log("error in document url " + err.response.data);
    }
  };

  const saveToLocal = async () => {
    let uniqueC = generateUniqueCode();
    let ticket = await getLastestTicket();

    let docName = "Practidocumentos" + uniqueC + ".pdf";

    let orderForm = {
      referenceCode: "lpx79mct9l72sg4220p",
      description: "Solicitud de copias - Impuesto predial.",
      amount: 8990,
      userEmail: "juan@gmail.com",
      userName: "juanse",
      userLastName: "Gomez",
      userIdType: "cedula",
      userId: "12541",
      userPhone: "325487447",
    };
    secureLocalStorage.setItem("order", JSON.stringify(orderForm));

    let docForm = {
      documentType: "P_doc1",
      documentName: "Solicitud de copias - Impuesto predial.",
      answers: {
        "1_residenceCity": "Cali",
        "5_applicationDate": "2023-12-08",
        "1_officialName": "carlos",
        "1_officialPosition": "admin",
        "1_entityAddress": "carrera 5 #6",
        "4_entityEmail": "juanc@hotmail.com",
        "1_entityCity": "Cali",
        "1_petitionerName": "juan gomez",
        "1_identificationType": "Cédula de ciudadanía",
        "1_identificationNumber": "1112458795",
        "1_petitionerRole": "admin",
        "1_cadastralNumber": "3254874",
        "4_notificationEmail": "juan@gmail.com",
        "1_notificationAddress": "carrera 4 ",
        "1_petitionerPhoneNumber": "3215487474",
      },
    };

    secureLocalStorage.setItem("docFormData", JSON.stringify(docForm));

    let orderResponse = {
      state: "Transacción aprobada",
      id: "828214a6-23e9-46bc-87ab-8dcdf9966771",
      codigoFactura: ticket,
      codigoAutorizacion: "2150868743",
      amount: "8,990",
      description: "Solicitud de copias - Impuesto predial.",
    };

    localStorage.setItem("orderData", JSON.stringify(orderResponse));

    //De esta aun no se ve nada Pendiente revisar

    secureLocalStorage.setItem("testing", JSON.stringify(orderForm));
  };

  const showAmount = () => {
    let orderData = JSON.parse(secureLocalStorage.getItem("testing"));
    let cantidad = parseInt(orderData.amount);
    console.log("AMOUNT:" + cantidad);
  };
  const showDate = () => {
    let today = new Date();
    let date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    let time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    let date_time = date + " " + time;
    return date_time;
  };

  const showUnique = () => {
    console.log(generateUniqueCode());
  };

  const createWord_document = async () => {
    try {
      let data = {
        main: "doc",
        documentType: "P_doc2",
        documentName:
          "Solicitud de prescripción impuesto predial por indebida notificación del mandamiento de pago.",
        answers: {
          "1_one": "",
          "1_residenceCity": "Caracolí",
          "5_applicationDate": "2023-12-21",
          "1_officialName": "carlos",
          "1_officialPosition": "secretario",
          "1_entityAddress": "",
          "4_entityEmail": "",
          "1_entityCity": "Cali",
          "1_petitionerName": "Juan Gomez",
          "1_identificationType": "Cédula de ciudadanía",
          "1_identificationNumber": "1212145874",
          "1_petitionerRole": "heredero",
          "1_cadastralNumber": "252541515",
          "1_propertyOwnerName": "Juan Gomez",
          "1_liquidationNumber": "265161563",
          "5_liquidationDate": "2023-12-14",
          "1_liquidationValue": "150000000",
          "1_notificationMethod": "correo electronico",
          "1_paymentOrderNumber": "1458894784",
          "5_paymentOrderDate": "2023-12-20",
          "4_notificationEmail": "juan@gmail.com",
          "1_notificationAddress": "carrera 8 #10-33",
          "1_petitionerPhoneNumber": "3214789658",
        },
      };

      data.userEmail = "juanc7795@gmail.com";
      data.rType = "word";
      // props.currentDoc_formData(data);
      // console.log(data);

      const webUrl =
        "https://practidocumentos.com/backend/documents/docsController.php";
      const localURL =
        "http://localhost/practidocs/backend/documents/docsController.php";
      const res = await axios.post(localURL, JSON.stringify(data), {
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log(res.data);
      download_word(res.data[0], res.data[1]);
    } catch (err) {
      console.log("error in document url " + err.response.data);
    }
  };

  const download_word = async (filename, html) => {
    let preHtml =
      "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w=urn:schemas-microsoft-com:office:office";
    let postHtml = "</body></html>";
    let finalHtml = preHtml + html;

    let blob = new Blob(["\ufeff", html], {
      type: "application/msword",
    });

    let formData = new FormData();
    formData.append("file", blob, filename + ".doc");
    formData.append("type", "word_save");

    try {
      const webUrl =
        "https://practidocumentos.com/backend/documents/saveWordDoc.php";
      const localURL =
        "http://localhost/practidocs/backend/documents/saveWordDoc.php";

      const res = await axios.post(localURL, formData);
      console.log(res);
    } catch (err) {
      console.log("error in document url " + err.response.data);
    }

    let url =
      "data:application/vnd.ms-word;charset=utf-8," +
      encodeURIComponent(finalHtml);
    let name = filename + ".doc";
    //console.log(url);
    let downloadlink = document.createElement("a");
    downloadlink.href = url;
    downloadlink.download = name;
    downloadlink.click();
    //send_word_email(url);
  };

  const send_word_email = async () => {
    let data = {
      main: "mail",
      documentName:
        "Solicitud de prescripción impuesto predial por indebida notificación del mandamiento de pago.",
    };
    data.userEmail = "juanc7795@gmail.com";
    try {
      const webUrl =
        "https://practidocumentos.com/backend/documents/docsController.php";
      const localURL =
        "http://localhost/practidocs/backend/documents/docsController.php";
      const res = await axios.post(localURL, data, {
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log(res);
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log("ERROR FROM SERVER");
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log("REQUEST NO RESPONSE");
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        console.log("ERROR IN THE REQUEST");
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
    }
  };

  function allProcess() {
    generateDoc();
    createWord_document();
    send_word_email();
  }

  //TESTING TU COMPRA_______________________________
  const accountId = "a6e42frm73d60rg7";
  const responseUrl =
    "https://practidocumentos.com/backend/controllers/test_orderResponse.php";
  const confirmationUrl =
    "https://practidocumentos.com/backend/controllers/orderConfirmation.php";

  const [OrderValues, setOrderValues] = useState({});

  const [clickedImg, setClickedImg] = useState(null);

  const [confirmationModal, setConfirmationModal] = useState(false);

  const handleImageClick = () => {
    setClickedImg(true);
  };

  const { sharedOrderData, updateSharedData } = useContext(
    SharedDataOrderContext
  );
  const updateData = (value) => {
    updateSharedData({ referenceCode: value });
  };

  const handelFormSubmit = async () => {
    const factura = generateUniqueCode();

    const buyerEmail = document.querySelector("input[name=buyerEmail]").value;
    let data = {
      usuario: accountId,
      factura: factura,
      valor: 8999,
      descripcionFactura: "Realizando pruebas de compra",
      correoComprador: buyerEmail,
      nombreComprador: document.querySelector("input[name='userName']").value,
      apellidoComprador: document.querySelector("input[name='userLastName']")
        .value,
      tipoDocumento: document.querySelector("#idTypeSelect").value,
      documentoComprador: document.querySelector("input[name='userId']").value,
      celularComprador: document.querySelector("input[name='userPhone']").value,
      direccionComprador: "",
      tipoMoneda: "COP",
      responseUrl: responseUrl,
      confirmationUrl: confirmationUrl,
    };
    setOrderValues(data);
    // console.log(data);
    updateData(factura);
    let orderData = {
      referenceCode: factura,
      description: sharedOrderData.description,
      amount: sharedOrderData.amount,
      correoComprador: buyerEmail,
      nombreComprador: document.querySelector("input[name='userName']").value,
      apellidoComprador: document.querySelector("input[name='userLastName']")
        .value,
      tipoDocumento: document.querySelector("#idTypeSelect").value,
      documentoComprador: document.querySelector("input[name='userId']").value,
      celularComprador: document.querySelector("input[name='userPhone']").value,
      direccionComprador: "",
    };
    local_Order_data(orderData);
    create_form(data);
  };

  const local_Order_data = (orderData) => {
    secureLocalStorage.setItem("order", JSON.stringify(orderData));
  };

  const create_form = (data) => {
    let form = ` <form id="payUForm" method="post" action="https://demover3-1.tucompra.net/tc3/app/inputs/compra.jsp">
                <input name="usuario"      type="hidden"  value='${data.usuario}'   />
                <input name="factura"     type="hidden"  value='${data.factura}'  />
                <input name="valor"   type="hidden"  value='${data.valor}'  />
                <input name="descripcionFactura"          type="hidden"  value='${data.descripcionFactura}'  />
                <input name="documentoComprador"             type="hidden"  value='${data.documentoComprador}'  />
                <input name="tipoDocumento"   type="hidden"  value='${data.tipoDocumento}'  />
                <input name="nombreComprador"        type="hidden"  value='${data.nombreComprador}'  />
                <input name="apellidoComprador"       type="hidden"  value='${data.apellidoComprador}'  />
                <input name="correoComprador"       type="hidden"  value='${data.correoComprador}'  />
                <input name="celularComprador"            type="hidden"  value='${data.celularComprador}'  />
                <input name="direccionComprador"      type="hidden"  value='${data.direccionComprador}'  />
                <input name="responseUrl"     type="hidden"  value='${data.responseUrl}'  />
                <input name="confirmationUrl" type="hidden"  value='${data.confirmationUrl}'  />
               
            </form>`;

    let container = document.querySelector("#payUContainer");
    container.innerHTML = form;

    let payUform = document.querySelector("#payUForm");
    payUform.submit();
  };

  const [isInputSelected, setIsInputSelected] = useState(false);
  const showModal = () => {
    if (!isInputSelected) {
      setIsInputSelected(true);
      setConfirmationModal(true);
      console.warn("Phone Field");
    }
  };

  const getLastestTicket = async () => {
    const webUrl =
      "https://practidocumentos.com/backend/controllers/orderController.php";
    const localURL =
      "http://localhost/practidocs/backend/controllers/orderController.php";

    const data = {
      type: "latestTicket",
    };
    const res = await axios.post(webUrl, data, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("GET TICKET: " + JSON.stringify(res.data));
    if (res.data !== null && res.data !== undefined && res.data !== "") {
      const ticket = res.data.split("-");
      if (ticket.length == 2) {
        const ticketNumber = "PD-" + (Number(ticket[1]) + 1);
        return ticketNumber;
      } else {
        return "PD-1";
      }
    } else {
      return "PD-1";
    }
  };

  return (
    <div>
      <div>
        <button onClick={sendToDB}>Save to DB</button>

        <button onClick={generateDoc}>Generar doc</button>

        <button onClick={saveToLocal}>Save to Local</button>

        <button onClick={sendEmail}>email</button>

        <button onClick={showAmount}>Show Amount</button>

        <button onClick={showDate}>Show Date</button>
        <button onClick={showUnique}>Show codigo unico</button>

        <button onClick={createWord_document}>Crear documetno de word</button>
        <button onClick={send_word_email}>email word doc</button>
        <button onClick={allProcess}>create and send email</button>
        <button onClick={getLastestTicket}>Obtener el ultimo ticket</button>
      </div>
      <div>
        <Container fluid={true} className="">
          {confirmationModal && (
            <Modal_pay_guide confirmationModal={setConfirmationModal} />
          )}
          <Row className="text-center">
            <Col id="purchase_guide_container" md={6} className="mt-4">
              <h2>Guía para el proceso de pago</h2>
              <p className="mt-5">
                A continuación te presentamos imagenes de muestra con el paso a
                paso para terminar el proceso de compra y obtener tu documento.{" "}
              </p>
              <Col xs={12} md={12}>
                <img
                  className="docHelpImage"
                  src={guideImage1}
                  alt="Guía de Pago"
                  onClick={() => handleImageClick()}
                />
              </Col>
              <div>
                {clickedImg && (
                  <Modal_order_images setClickedImg={setClickedImg} />
                )}
              </div>
            </Col>
            <Col md={6}>
              <h2 className="mt-4">Datos para realizar la compra</h2>
              <Form className="mt-5">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="">Nombre </Form.Label>
                  <Form.Control
                    name="userName"
                    type="text"
                    placeholder="Ingresa tu nombre"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="">Apellidos </Form.Label>
                  <Form.Control
                    name="userLastName"
                    type="text"
                    placeholder="Ingresa tus apellidos"
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="">
                  <Form.Label className="">Tipo de documento </Form.Label>
                  <Form.Select
                    id="idTypeSelect"
                    aria-label="Default select example"
                  >
                    <option>Tipo de documento</option>
                    <option value="cedula de ciudadania">
                      Cedula de ciudadania
                    </option>
                    <option value="pasaporte">Pasaporte</option>
                    <option value="cedula_ex">Cedula de extranjería</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Documento </Form.Label>
                  <Form.Control
                    type="text"
                    name="userId"
                    placeholder="Numero de documento"
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Correo Electronico</Form.Label>
                  <Form.Control
                    type="email"
                    name="buyerEmail"
                    placeholder="Correo Electronico"
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Telefono Celular</Form.Label>
                  <Form.Control
                    type="text"
                    name="userPhone"
                    placeholder="Numero de telefono"
                    maxLength={10}
                  />
                </Form.Group>

                <Button className="third-color" onClick={handelFormSubmit}>
                  Realizar pago{" "}
                  <Image className="pseLogo" src={tuCompra}></Image>
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
        <div id="payUContainer"></div>
      </div>
    </div>
  );
};

export default MainTestPage;
