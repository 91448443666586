import React, { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import tuCompraLogo from "../images/tu_compra_logo.png";
import SharedDataOrderContext from "../helpers/sharedOrderContext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";
import guideImage1 from "../images/purchaseGuide/step1.png";
import Modal_order_images from "../components/order_purchaseModal";
import Modal_pay_guide from "./order_payuGuide_model";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";

const OrderFormTuCompra = (props) => {
  const account_production = "jhglb0269cm978u5";
  const accountId = "a6e42frm73d60rg7";
  const responseUrl =
    "https://practidocumentos.com/backend/controllers/test_orderResponse_tuCompra.php";
  const confirmationUrl =
    "https://practidocumentos.com/backend/controllers/orderConfirmation.php";

  const production_url =
    "https://gateway2.tucompra.com.co/tc3/app/inputs/compra.jsp";
  const demo_url = "https://demover3-1.tucompra.net/tc3/app/inputs/compra.jsp";

  const { sharedOrderData, updateSharedData } = useContext(
    SharedDataOrderContext
  );

  const [OrderValues, setOrderValues] = useState({});

  const [clickedImg, setClickedImg] = useState(null);

  const [confirmationModal, setConfirmationModal] = useState(false);

  const updateData = (value) => {
    updateSharedData({ referenceCode: value });
  };

  const handleImageClick = () => {
    setClickedImg(true);
  };

  const generateUniqueCode = () => {
    // Generate a timestamp to make the code partially time-based
    const timestamp = Date.now().toString(36);

    // Generate a random number or string to add randomness
    const randomPart = Math.random().toString(36).slice(2);

    // Combine the timestamp and random part to create a unique code
    const code = timestamp + randomPart;

    return code;
  };

  const handleEncrypt = async (textToEncrypt) => {
    // Crear un objeto para calcular el hash SHA-256
    const utf8 = new TextEncoder().encode(textToEncrypt);
    const hashBuffer = await crypto.subtle.digest("SHA-256", utf8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((bytes) => bytes.toString(16).padStart(2, "0"))
      .join("");
    return hashHex;
  };
  const getLastestTicket = async () => {
    const webUrl =
      "https://practidocumentos.com/backend/controllers/orderController.php";
    const localURL =
      "http://localhost/practidocs/backend/controllers/orderController.php";

    let data = {
      type: "latestTicket",
    };
    const res = await axios.post(webUrl, data, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (res.data !== null && res.data !== undefined && res.data !== "") {
      let ticket = res.data.split("-");
      const ticketNumber = "PD-" + (Number(ticket[1]) + 1);
      return ticketNumber;
    } else {
      return "PD-1";
    }
  };

  const handelFormSubmit = async () => {
    const factura = await getLastestTicket();

    const buyerEmail = document.querySelector("input[name=buyerEmail]").value;
    let data = {
      usuario: account_production,
      factura: factura,
      valor: sharedOrderData.amount,
      descripcionFactura: sharedOrderData.description,
      correoComprador: buyerEmail,
      nombreComprador: document.querySelector("input[name='userName']").value,
      apellidoComprador: document.querySelector("input[name='userLastName']")
        .value,
      tipoDocumento: document.querySelector("#idTypeSelect").value,
      documentoComprador: document.querySelector("input[name='userId']").value,
      celularComprador: document.querySelector("input[name='userPhone']").value,
      direccionComprador: "",
      tipoMoneda: "COP",
      responseUrl: responseUrl,
      confirmationUrl: confirmationUrl,
    };
    setOrderValues(data);
    // console.log(data);
    updateData(factura);
    let orderData = {
      referenceCode: factura,
      description: sharedOrderData.description,
      amount: sharedOrderData.amount,
      correoComprador: buyerEmail,
      nombreComprador: document.querySelector("input[name='userName']").value,
      apellidoComprador: document.querySelector("input[name='userLastName']")
        .value,
      tipoDocumento: document.querySelector("#idTypeSelect").value,
      documentoComprador: document.querySelector("input[name='userId']").value,
      celularComprador: document.querySelector("input[name='userPhone']").value,
      direccionComprador: "",
    };
    local_Order_data(orderData);
    create_form(data);
  };

  const local_Order_data = (orderData) => {
    secureLocalStorage.setItem("order", JSON.stringify(orderData));
  };

  const create_form = (data) => {
    let form = ` <form id="payUForm" method="post" action="${production_url}">
                <input name="usuario"      type="hidden"  value='${data.usuario}'   />
                <input name="factura"     type="hidden"  value='${data.factura}'  />
                <input name="valor"   type="hidden"  value='${data.valor}'  />
                <input name="descripcionFactura"          type="hidden"  value='${data.descripcionFactura}'  />
                <input name="documentoComprador"             type="hidden"  value='${data.documentoComprador}'  />
                <input name="tipoDocumento"   type="hidden"  value='${data.tipoDocumento}'  />
                <input name="nombreComprador"        type="hidden"  value='${data.nombreComprador}'  />
                <input name="apellidoComprador"       type="hidden"  value='${data.apellidoComprador}'  />
                <input name="correoComprador"       type="hidden"  value='${data.correoComprador}'  />
                <input name="celularComprador"            type="hidden"  value='${data.celularComprador}'  />
                <input name="direccionComprador"      type="hidden"  value='${data.direccionComprador}'  />
                <input name="responseUrl"     type="hidden"  value='${data.responseUrl}'  />
                <input name="confirmationUrl" type="hidden"  value='${data.confirmationUrl}'  />
               
            </form>`;

    let container = document.querySelector("#payUContainer");
    container.innerHTML = form;

    let payUform = document.querySelector("#payUForm");
    payUform.submit();
  };

  const [isInputSelected, setIsInputSelected] = useState(false);
  const showModal = () => {
    if (!isInputSelected) {
      setIsInputSelected(true);
      setConfirmationModal(true);
      console.warn("Phone Field");
    }
  };

  return (
    <div>
      <Container fluid={true} className="">
        {confirmationModal && (
          // eslint-disable-next-line react/jsx-pascal-case
          <Modal_pay_guide confirmationModal={setConfirmationModal} />
        )}
        <Row className="text-center">
          <Col id="purchase_guide_container" md={6} className="mt-4">
            <h2>Guía para el proceso de pago</h2>
            <p className="mt-5">
              A continuación te presentamos imagenes de muestra con el paso a
              paso para terminar el proceso de compra y obtener tu documento.{" "}
            </p>
            <Col xs={12} md={12}>
              <img
                className="docHelpImage"
                src={guideImage1}
                alt="Guía de Pago"
                onClick={() => handleImageClick()}
              />
            </Col>
            <div>
              {clickedImg && (
                <Modal_order_images setClickedImg={setClickedImg} />
              )}
            </div>
          </Col>
          <Col md={6}>
            <h2 className="mt-4">Datos para realizar la compra</h2>
            <Form className="mt-5">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="">Nombre </Form.Label>
                <Form.Control
                  name="userName"
                  type="text"
                  placeholder="Ingresa tu nombre"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="">Apellidos </Form.Label>
                <Form.Control
                  name="userLastName"
                  type="text"
                  placeholder="Ingresa tus apellidos"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="">
                <Form.Label className="">Tipo de documento </Form.Label>
                <Form.Select
                  id="idTypeSelect"
                  aria-label="Default select example"
                >
                  <option>Tipo de documento</option>
                  <option value="cedula de ciudadania">
                    Cedula de ciudadania
                  </option>
                  <option value="pasaporte">Pasaporte</option>
                  <option value="cedula_ex">Cedula de extranjería</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Documento </Form.Label>
                <Form.Control
                  type="text"
                  name="userId"
                  placeholder="Numero de documento"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Correo Electronico</Form.Label>
                <Form.Control
                  type="email"
                  name="buyerEmail"
                  placeholder="Correo Electronico"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Telefono Celular</Form.Label>
                <Form.Control
                  type="text"
                  name="userPhone"
                  placeholder="Numero de telefono"
                  maxLength={10}
                  onClick={showModal}
                />
              </Form.Group>

              <Button className="third-color" onClick={handelFormSubmit}>
                Realizar pago{" "}
                <Image className="pseLogo" src={tuCompraLogo}></Image>
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
      <div id="payUContainer"></div>
    </div>
  );
};

export default OrderFormTuCompra;
