import React from "react";
import MainBanner from "./main-banner";
import MainDocuments from "./main-documents";
import MainServices from "./main-services";
import MainAboutus from "./main-aboutus";
import MainContact from "./main-contact";
import MainNavbar from "../components/navbar";

const MainPage = () => {
  return (
    <div>
      <MainNavbar />
      <MainBanner />
      <MainDocuments />
      <MainServices />
      <MainAboutus />
      <MainContact />
      <a
        href="https://api.whatsapp.com/send?phone=3128013791&text=Me%20interesa%20recibir%20asesoría"
        className="wppIcon"
        target="_blank"
        rel="noreferrer"
      >
        <i className="bi bi-whatsapp my-wppIcon"></i>
      </a>
    </div>
  );
};

export default MainPage;
