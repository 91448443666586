import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const MainContact = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div id="mainContact" className="">
      <Container fluid>
        <Row className="d-flex justify-content-around">
          <Col md={8} className="mt-5">
            <Row className="d-flex justify-content-center align-items-center">
              <Col
                xs={10}
                md={6}
                id="contactFormleft"
                className="d-flex justify-content-center align-items-center"
              >
                <Col md={6}>
                  <div className="">
                    <h5>Numero de contacto</h5>
                    <span className="text-muted-white">+(57) 312 801 3791</span>
                  </div>

                  <div className="mt-5">
                    <h5>Correo de soporte </h5>
                    <span className="text-muted-white">
                      practidocumentoslegal@gmail.com
                    </span>
                  </div>
                </Col>
              </Col>
              <Col
                xs={10}
                md={6}
                id="contactFormright"
                className="d-flex justiy-content-center align-items-center"
              >
                <Form id="contactForm" className="text-center p-4">
                  <Row>
                    <h3>Envianos un mensaje</h3>
                  </Row>
                  <Row className="mt-4">
                    <Col>
                      <Form.Control type="text" placeholder="Nombre" />
                    </Col>
                    <Col>
                      <Form.Control type="text" placeholder="Apellido" />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col>
                      <Form.Control
                        type="email"
                        placeholder="ejemplo@gmail.com"
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col>
                      <Form.Control
                        type="number"
                        placeholder="Telefono de contacto"
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Mensaje"
                      />
                    </Col>
                  </Row>

                  <Button
                    variant="primary"
                    className="secondaryColor mt-4"
                    type="submit"
                  >
                    Enviar
                  </Button>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col id="footer" className="mt-5">
            <Row className="d-flex justify-content-around align-items-center">
              <Col xs={12} md={4} className="text-white text-center mt-3">
                <Row>
                  <Col className="social-Networks-Container">
                    <i className="bi bi-facebook"></i>
                  </Col>
                  <Col className="social-Networks-Container">
                    <i className="bi bi-youtube"></i>
                  </Col>
                  <Col className="social-Networks-Container">
                    <i className="bi bi-instagram"></i>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={4} className="text-white text-center mt-3">
                <p>Bogotá</p>
                <p>+(57) 312 801 3791</p>
                <p>practidocumentoslegal@gmail.com</p>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center mt-3">
              <Col xs={12} md={4} className="text-white text-center">
                <p>Todos los derechos reservados</p>
                <p>{currentYear}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MainContact;
