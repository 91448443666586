import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const LeftDrawer = (props) => {
    const handleLogout = () => {
        // Handle logout logic here
    };

    return (
       
            <Col md={3} className="leftDrawer ">
                <Row>                  
                    <Col xs={12} className="nameIcon">MC</Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <ul>
                            <li>Home</li>
                            <li>Password</li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="logOut" onClick={props.handleLogout}>
                        Logout
                    </Col>
                </Row>
            </Col>
        


    );
}


export default LeftDrawer;
