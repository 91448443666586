import React, { useState, useContext, useRef, useEffect } from "react";
import * as QuestionsBank from "../helpers/questionsBank.js";
import { LoginContext } from "../helpers/context";
import Help_images from "../helpers/questions_images.js";
import Modal_Docs_images from "./help_images_modal.js";
import colombia from "../helpers/colombia.js";
import SharedDataOrderContext from "../helpers/sharedOrderContext";
import secureLocalStorage from "react-secure-storage";
import { Alert, Carousel, Form, Button, Col, Row } from "react-bootstrap";
import { Type } from "react-bootstrap-icons";

const DocsF = (props) => {
  const inputsRefs = useRef([0]);
  /*
        useEffect(() => {
            inputRefs[props.index].current;
           console.log( inputRefs[props.index].current);
            // Focus on the input when the currentIndex changes
        }, [props.index]);*/

  useEffect(() => {
    let index = props.index;
    if (props.index == null) {
      index = 0;
    }
    let carouselItems = document.querySelectorAll(
      ".carousel .carousel-item input"
    );
    carouselItems[index].focus();
    if (carouselItems[index].value == "") {
      const newData = { ...formResponses };
      newData[QuestionsBank[props.currentDoc][index].name] = "";
      // console.log(newData);

      setFormResponses((prevResponses) => ({
        ...prevResponses,
        [QuestionsBank[props.currentDoc][index].name]: "",
      }));
    }
  }, [props.index]);

  const { urlDownload, setDownloadURl } = useContext(LoginContext);
  const [toggler, setToggler] = useState(false);

  const { sharedOrderData, updateSharedData } = useContext(
    SharedDataOrderContext
  );

  const [selectedOption, setSelectedOption] = useState("");

  const [isDropdownVisible, setDropdownVisible] = useState(true);

  //Variable to notify if there are pending questions to answer
  const [alert, setAlert] = useState(false);

  const [alertMessage, setAlertMessage] = useState(
    "Aún hay preguntas pendientes"
  );

  const [formResponses, setFormResponses] = useState({});

  const [formValidated, setFormValidated] = useState(false);

  const [showSuggestions, setsShowSuggestions] = useState(false);

  const [citySuggestions, setCitySuggestions] = useState({});

  const suggestions = document.querySelector(".suggestions");

  const [clickedImg, setClickedImg] = useState(null);

  const [readOnlyInput, setReadOnlyInput] = useState(false);

  const idTypeOptions = [
    "Cédula de ciudadanía",
    "Pasaporte",
    "Cédula de extranjería",
  ];

  const goBack = () => {
    props.goback("second");
  };

  const handleOptionClick = (option, questionName) => {
    //console.log('Option clicked' + option);

    const newData = { ...formResponses };
    newData[questionName] = option;
    setFormResponses((prevResponses) => ({
      ...prevResponses,
      [questionName]: option,
    }));
    displayIdTypes(false);
  };

  const handleInputChange = (questionName, event) => {
    let { value } = event.target;

    const { type } = event.target;

    if (questionName == "1_entityCity" || questionName == "1_residenceCity") {
      displayMatches(value);
      let entitiyCity = document.querySelectorAll("input[name='1_entityCity']");
      entitiyCity.forEach((element) =>
        element.addEventListener("keyup", closeSuggestions)
      );

      let residenceCity = document.querySelectorAll(
        "input[name='1_residenceCity']"
      );
      residenceCity.forEach((element) =>
        element.addEventListener("keyup", closeSuggestions)
      );
    }

    if (questionName == "1_identificationType") {
      displayIdTypes(true);
      setReadOnlyInput(true);
      setTimeout(() => {
        setReadOnlyInput(false);
      }, 2000);
    }
    if (type == "text") {
      value = capitalizeWords(value);
    }

    const newData = { ...formResponses };
    newData[questionName] = value;
    // console.log(newData);

    setFormResponses((prevResponses) => ({
      ...prevResponses,
      [questionName]: value,
    }));
    //console.log(formResponses);

    setFormValidated(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const unansweredQuestions = QuestionsBank[props.currentDoc].filter(
      (question) => !formResponses[question.name]
    );
    //console.log('Preguntas sin responder: ' + unansweredQuestions[0].require);

    const unansweredQuestions_withoutOptionals = unansweredQuestions.filter(
      (question) => question.require
    );

    const questions_email = QuestionsBank[props.currentDoc].filter(
      (question) => formResponses[question.name] && question.type == "email"
    );

    questions_email.forEach((input) => {
      let value = document.querySelector(`input[name="${input.name}"]`).value;
      // console.log(value);
      if (!validateEmail(value)) {
        setAlertMessage(
          "Por favor verifica que el correo electrónico es válido"
        );
        setAlert(true);
      } else {
        setAlertMessage("Aún hay preguntas pendientes por responder");
      }
    });

    // console.log(unansweredQuestions_withoutOptionals);

    if (unansweredQuestions_withoutOptionals.length === 0) {
      // Todas las preguntas han sido respondidas, puedes realizar alguna acción, como enviar los datos al servidor.
      persistFormData();
      setFormValidated(true);
    } else {
      // Mostrar un mensaje de error o tomar alguna acción en caso de preguntas sin responder.
      setFormValidated(false);
      setAlert(true);
      // console.log('¡Por favor, responde todas las preguntas!');
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const validate = emailRegex.test(email);
    return validate;
  };

  const capitalizeWords = (str) => {
    // Split the string into an array of words
    let words = str.split(" ");

    // Iterate through each word in the array
    for (let i = 0; i < words.length; i++) {
      // Check if the word has more than three letters
      if (words[i].length > 3) {
        // Capitalize the first letter of the word
        words[i] = words[i][0].toUpperCase() + words[i].slice(1);
      }
    }

    // Join the modified array of words back into a string
    return words.join(" ");
  };

  const updateData = (value) => {
    updateSharedData({ doc_url_download: value });
  };

  const persistFormData = () => {
    let data = {
      documentType: props.currentDoc,
      documentName: props.currentDocName,
      answers: formResponses,
    };
    //console.log(JSON.stringify(data));
    secureLocalStorage.setItem("docFormData", JSON.stringify(data));
    props.goback("fourth");
  };

  const handleSelect = (selectedIndex, e) => {
    let currentSlide = document.querySelector(".carousel-item.active input");
    if (showSuggestions) {
      setsShowSuggestions(false);
      setCitySuggestions(null);
    }
    props.indexHandler(selectedIndex);
  };

  const handleClick = (item) => {
    setClickedImg(item.link);
  };

  function findMatches(wordToMatch, colombia) {
    let onlyCities = colombia.map((city) => city.ciudades);
    let fil = colombia.filter((place) => {
      const regex = new RegExp(wordToMatch, "gi");
      let cityN = [];
      cityN = place.ciudades.find((city) => city.match(regex));
      return cityN;
    });
    return onlyCities.join().split(",");
  }

  const citySelected = (e, questionName) => {
    if (e.target.localName == "li") {
      // console.log(e.target.innerText);
      const newData = { ...formResponses };
      newData[questionName] = e.target.innerText;
      // console.log(newData);

      setFormResponses((prevResponses) => ({
        ...prevResponses,
        [questionName]: e.target.innerText,
      }));
      setsShowSuggestions(false);
      setCitySuggestions(null);
    }
  };
  const closeSuggestions = () => {
    setTimeout(() => {
      setsShowSuggestions(false);
      setCitySuggestions(null);
    }, 10000);
  };

  function displayMatches(value) {
    const matchArray = findMatches(value, colombia);
    const regex = new RegExp(value, "gi");
    const cityName = matchArray.filter((city) => {
      return city.match(regex);
    });
    let html = "";
    cityName.forEach((name) => {
      html += ` <li className="suggestion-ttem">
        ${name}
      
      </li>`;
    });
    setsShowSuggestions(true);
    setCitySuggestions(html);
  }

  const displayIdTypes = (value) => {
    //console.log('Display list method called');
    setDropdownVisible(value);
  };

  return (
    <Row className="mb-5">
      <Col>
        <Row>
          <Col md={2}>
            <i
              id="backArrowDocs"
              onClick={goBack}
              className="bi bi-arrow-left-circle-fill"
            ></i>
          </Col>
        </Row>
        <Row className="text-center mt-3">
          <h3>{props.currentDocName}</h3>
        </Row>
        <Row className="justify-content-sm-center justify-content-center mt-5">
          <Col
            xs={12}
            md={4}
            className="docsDescContainer text-center  smoothbg"
            dangerouslySetInnerHTML={{ __html: props.desc }}
          ></Col>
          <Col xs={12} md={8}>
            <Row>
              <Col xs={12} md={12} id="DocsForm-Container">
                <Row className="text-center fw-bold">
                  <h5>Formulario</h5>
                  <Col className="d-flex text-center justify-content-center align-items-center">
                    <p className="">
                      Debajo del formulario encontraras una guía para ciertos
                      campos.
                    </p>
                    <div className="aidHandRotate">
                      <i className="bi bi-hand-index-fill"></i>
                    </div>
                  </Col>
                </Row>
                <Row className="alertContainer">
                  <Col className="alert_message">
                    <Alert
                      show={alert}
                      variant="danger"
                      onClose={() => setAlert(false)}
                      dismissible
                    >
                      <Alert.Heading>{alertMessage}</Alert.Heading>
                    </Alert>
                  </Col>
                </Row>

                <Row className="questionsForm-container">
                  <Form className="questionsForm" onSubmit={handleSubmit}>
                    <Carousel
                      variant="dark"
                      interval={null}
                      activeIndex={props.index}
                      onSelect={handleSelect}
                      wrap={false}
                    >
                      {QuestionsBank[props.currentDoc].map(
                        (question, index) => (
                          <Carousel.Item key={question.name}>
                            <Row className="mt-5 p-5 d-flex justify-content-center align-items-center ">
                              <Form.Group className="questionContainer">
                                <Form.Label className="">
                                  {question.label}
                                </Form.Label>
                                <Form.Control
                                  type={question.type}
                                  placeholder={question.placeholder}
                                  value={formResponses[question.name] || ""}
                                  onChange={(event) =>
                                    handleInputChange(question.name, event)
                                  }
                                  isInvalid={
                                    !formValidated &&
                                    !formResponses[question.name] &&
                                    question.require
                                  }
                                  onKeyUp={
                                    question.name == "1_entityCity" ||
                                    question.name == "1_residenceCity"
                                      ? closeSuggestions
                                      : null
                                  }
                                  autoFocus={true}
                                  readOnly={readOnlyInput}
                                  ref={inputsRefs[index]}
                                  name={question.name}
                                />

                                <ul
                                  onClick={(event) =>
                                    citySelected(event, question.name)
                                  }
                                  className={`suggestions ${
                                    showSuggestions ? "d-block" : "d-none"
                                  }`}
                                  dangerouslySetInnerHTML={{
                                    __html: citySuggestions,
                                  }}
                                ></ul>
                                {question.name == "1_identificationType" &&
                                isDropdownVisible ? (
                                  <ul className="idTypes">
                                    {idTypeOptions.map((option, index) => (
                                      <li
                                        key={index}
                                        onClick={() =>
                                          handleOptionClick(
                                            option,
                                            question.name
                                          )
                                        }
                                      >
                                        {option}
                                      </li>
                                    ))}
                                  </ul>
                                ) : (
                                  ""
                                )}

                                {!formValidated &&
                                  !formResponses[question.name] && (
                                    <Form.Control.Feedback type="invalid">
                                      ¡Por favor, responde esta pregunta!
                                    </Form.Control.Feedback>
                                  )}
                              </Form.Group>
                            </Row>
                          </Carousel.Item>
                        )
                      )}
                    </Carousel>

                    <Row className="justify-content-center ">
                      <Col
                        md={12}
                        className="justify-content-center text-center"
                      >
                        <Form.Group className="mt-3">
                          <Form.Control type={"submit"} value={"terminar"} />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </Row>
              </Col>
            </Row>
            <Row
              className={
                QuestionsBank[props.currentDoc][props.index].aid["message"] !=
                ""
                  ? "justify-content-center align-items-center aids_row_container"
                  : "d-none"
              }
            >
              <h6 className="mt-sm-4 mt-3 text-center">
                A continuación encontraras indicativos para ayudarte a responder
                las preguntas del formulario:
              </h6>
              <Col
                xs={11}
                md={11}
                id="documentsAidsSupport"
                className="d-flex justify-content-center align-items-center mt-4"
              >
                <Row>
                  <Col xs={12} md={12}>
                    <p>
                      {" "}
                      {
                        QuestionsBank[props.currentDoc][props.index].aid[
                          "message"
                        ]
                      }
                    </p>
                  </Col>
                  <Col xs={12} md={12}>
                    <p>
                      {QuestionsBank[props.currentDoc][props.index].aid[
                        "image"
                      ] ? (
                        <img
                          className="docHelpImage"
                          src={
                            Help_images[
                              QuestionsBank[props.currentDoc][props.index].aid[
                                "image"
                              ]
                            ].link
                          }
                          alt={
                            Help_images[
                              QuestionsBank[props.currentDoc][props.index].aid[
                                "image"
                              ]
                            ].text
                          }
                          onClick={() =>
                            handleClick(
                              Help_images[
                                QuestionsBank[props.currentDoc][props.index]
                                  .aid["image"]
                              ]
                            )
                          }
                        />
                      ) : (
                        ""
                      )}
                    </p>
                  </Col>
                </Row>

                <div>
                  {clickedImg && (
                    <Modal_Docs_images
                      clickedImg={clickedImg}
                      setClickedImg={setClickedImg}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default DocsF;
