import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const Modal_order_confirmation = (props) => {
  const handleClick = (e) => {
    if (e.target.classList.contains("dismiss")) {
      props.confirmationModal(false);
    }

  };

  return (
    <>
      <div className="overlay dismiss" onClick={handleClick}>
        <div
          className="modal show dismiss"
          style={{ display: 'block', position: 'middle' }}
        >
          <Modal.Dialog centered={true} >


            <Modal.Header className='dismiss' >
              <Modal.Title>Notificación</Modal.Title>
              <span className="dismiss" onClick={handleClick}>
                X
              </span>
            </Modal.Header>

            <Modal.Body>
              <p>Los certificados han sido generados <strong>satisfactoriamente</strong>, encontrara sus resultados en el panel principal y en su correo electrónico</p>
            </Modal.Body>

            <Modal.Footer className='text-center'>
              <Button className='dismiss' variant="success" onClick={handleClick}>Entendido</Button>

            </Modal.Footer>
          </Modal.Dialog>
        </div>

      </div>
    </>
  );
};

export default Modal_order_confirmation;