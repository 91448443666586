import React from 'react';



const ErrorPage = ()=>{


    return(
        <div>
            UPS lo siento la pagina que buscas no se encuentra disponible
        </div>
    );
}

export default ErrorPage;