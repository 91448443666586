import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const Protected = ({ component: Dash }) => {
  const nav = useNavigate();

  useEffect(() => {
    let login = secureLocalStorage.getItem("login");
    if (!login) {
      secureLocalStorage.setItem(
        "loginStatus",
        "Por favor inicia sesión para ingresar al dashboard"
      );
      nav("/ml", { replace: true });
    }
  }, []);

  return <Dash />;
};

export default Protected;
