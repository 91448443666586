import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import guide4 from "../images/purchaseGuide/step4.png";

const Modal_pay_guide = (props) => {
  const handleClick = (e) => {
    if (e.target.classList.contains("dismiss")) {
      props.confirmationModal(false);
    }
  };

  return (
    <>
      <div className="overlay dismiss" onClick={handleClick}>
        <div
          className="modal show dismiss"
          style={{ display: "block", position: "middle" }}
        >
          <Modal.Dialog centered={true}>
            <Modal.Header className="dismiss">
              <Modal.Title>Recordatorio</Modal.Title>
              <span className="dismiss" onClick={handleClick}>
                X
              </span>
            </Modal.Header>

            <Modal.Body>
              <p>
                Una vez realices el pago es nuestra plataforma aliada recuerda
                regresar a nuestro sitio web dando click o tap en el botón que
                dice <strong>"Finalizar"</strong> como se pude ver en la
                siguiente imagen.
              </p>
              <div className="text-center w-100">
                <img src={guide4} alt="Guía para regresar al comercio"></img>
              </div>
              <p>
                <strong>
                  Esto para que puedas descargar tus documentos y recibirlos via
                  email.
                </strong>
              </p>
            </Modal.Body>

            <Modal.Footer className="text-center">
              <Button
                className="dismiss"
                variant="success"
                onClick={handleClick}
              >
                Entendido
              </Button>
            </Modal.Footer>
          </Modal.Dialog>
        </div>
      </div>
    </>
  );
};

export default Modal_pay_guide;
