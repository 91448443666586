import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { Row, Col } from "react-bootstrap";
import guide1 from "../images/purchaseGuide/step1.png";
import guide2 from "../images/purchaseGuide/step2.png";
import guide3 from "../images/purchaseGuide/step3.png";
import guide4 from "../images/purchaseGuide/step4.png";
import guide5 from "../images/purchaseGuide/step5.png";
import guide6 from "../images/purchaseGuide/step6.png";

const Modal_order_images = (props) => {
  const handleClick = (e) => {
    if (e.target.classList.contains("dismiss")) {
      props.setClickedImg(false);
    }
  };

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <>
      <div className="overlay dismiss" onClick={handleClick}>
        <div className="purchase-modal-container d-flex justify-content-center align-items-center">
          <Row className="">
            <Col md={12}>
              <Carousel
                variant="dark"
                data-bs-theme="dark"
                activeIndex={index}
                onSelect={handleSelect}
                slide={false}
              >
                <Carousel.Item>
                  <img className="guideImage" alt="guide1" src={guide1} />
                  <Carousel.Caption></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img className="guideImage" alt="guide2" src={guide2} />
                  <Carousel.Caption></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img className="guideImage" alt="guide3" src={guide3} />
                  <Carousel.Caption></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img className="guideImage" alt="guide4" src={guide4} />
                  <Carousel.Caption></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img className="guideImage" alt="guide5" src={guide5} />
                  <Carousel.Caption></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img className="guideImage" alt="guide6" src={guide6} />
                  <Carousel.Caption></Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Modal_order_images;
