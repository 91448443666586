import React, { useState, useEffect } from "react";
import { json, useNavigate } from "react-router-dom";
import LeftDrawer from "../components/dashboard-drawer";
import { Button, Container, Col, Row, Form } from "react-bootstrap";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";

const Dashboard = () => {
  const nav = useNavigate();
  function logoutSubmit() {
    secureLocalStorage.setItem("login", false);
    secureLocalStorage.setItem("loginStatus", "logged out succesfully");
    nav("/ml");
  }

  const [showDrawer, setShowDrawer] = useState(false);

  const handleCloseDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  const [showSearchForm, setShowSearchForm] = useState(false);

  const show_search_form = () => {
    setShowSearchForm(true);
  };

  const checkLogin_status = async () => {
    let data = {
      type: "checkLogged",
    };
    const webUrl =
      "https://practidocumentos.com/backend/controllers/userController.php";
    const pruebas =
      "http://localhost/practidocs/backend/controllers/userController.php";
    const res = await axios.post(pruebas, data);

    //console.log(res.data);
    if (res.data === "loggedIn") {
      console.log("user logged in");
    } else {
      secureLocalStorage.setItem("login", false);
      secureLocalStorage.setItem("loginStatus", "logged out succesfully");
      nav("/ml");
      console.log("user not logged in");
    }
  };
  useEffect(() => {
    checkLogin_status();
  }, []);

  const [documentName, setDocumentName] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [pdf_data, setPdfData] = useState({});
  const [word_data, setWordData] = useState({});
  const handleSubmit = async (e) => {
    e.preventDefault();

    const referenceCode = document.querySelector(
      'input[name="referenceCode_input"]'
    ).value;

    const data = {
      type: "getByReference",
      referenceCode: referenceCode,
    };

    const webUrl =
      "https://practidocumentos.com/backend/controllers/orderController.php";
    const url =
      "http://localhost/practidocs/backend/controllers/orderController.php";
    const res = await axios.post(webUrl, data);
    console.log("Información de la orden: " + res.data);

    if (res.data != null && res.data !== "" && res.data !== "No found") {
      setShowResults(true);
      setDocumentName(res.data[0]);
    } else {
      setShowResults(true);
      setDocumentName("No se pudo encontrar ningún pedido con esa referencia");
    }

    let docForm = {
      main: "doc",
      documentType: res.data["d_type"],
      docName: res.data[0],
      answers: JSON.parse(res.data[3]),
      rType: "doc",
      userEmail: "",
    };
    //console.warn("DOCFORM:" + JSON.stringify(docForm));

    let docForm_word = {
      main: "doc",
      documentType: res.data["d_type"],
      docName: res.data[0],
      answers: JSON.parse(res.data[3]),
      rType: "word",
      userEmail: "",
    };
    setPdfData(docForm);
    setWordData(docForm_word);
  };
  const generateDoc = async (data) => {
    //console.log("PDF DATA" + JSON.stringify(data));
    try {
      // props.currentDoc_formData(data);
      // console.log(data);
      const webUrl =
        "https://practidocumentos.com/backend/documents/docsController.php";
      const localURL =
        "http://localhost/practidocs/backend/documents/docsController.php";
      const res = await axios.post(webUrl, data, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
        },
      });
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      //link.setAttribute('download', docFinalname);
      //document.body.appendChild(link);
      link.download = data.docName + ".pdf";
      link.dispatchEvent(new MouseEvent("click"));
      // setDownloadURl(url);
      //updateData(url);
      //props.goback('third');
    } catch (err) {
      console.log("error in document url " + err);
    }
  };
  const createWord_document = async (data) => {
    try {
      const webUrl =
        "https://practidocumentos.com/backend/documents/docsController.php";
      const localURL =
        "http://localhost/practidocs/backend/documents/docsController.php";
      const res = await axios.post(webUrl, data, {
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
        },
      });

      download_word(res.data[0], res.data[1]);
    } catch (err) {
      console.log("error in document url " + err);
    }
  };
  const download_word = async (filename, html) => {
    let preHtml =
      "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w=urn:schemas-microsoft-com:office:office";
    let postHtml = "</body></html>";
    let finalHtml = preHtml + html;

    let blob = new Blob(["\ufeff", html], {
      type: "application/msword",
    });

    let formData = new FormData();
    formData.append("file", blob, filename + ".doc");
    formData.append("type", "word_save");
    const localURL =
      "http://localhost/practidocs/backend/documents/saveWordDoc.php";
    const webUrl =
      "https://practidocumentos.com/backend/documents/saveWordDoc.php";

    const res = await axios.post(webUrl, formData);

    //console.log(res);

    let url =
      "data:application/vnd.ms-word;charset=utf-8," +
      encodeURIComponent(finalHtml);

    filename = filename + ".doc";
    let downloadlink = document.createElement("a");
    downloadlink.href = url;
    downloadlink.download = filename;
    downloadlink.click();
  };

  const handleDownload = () => {
    generateDoc(pdf_data);
    createWord_document(word_data);
  };

  return (
    <Container fluid={true}>
      <Row>
        <LeftDrawer
          show={showDrawer}
          handleClose={handleCloseDrawer}
          handleLogout={logoutSubmit}
        />
        <Col md={9} s>
          <h2>Bienvenid@ a tu dashboard </h2>
          <Row className="d-flex">
            <Col md={4}>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Codigo de referencia</Form.Label>
                  <Form.Control
                    name="referenceCode_input"
                    type="number"
                    placeholder="Enter number"
                    required={true}
                  />
                </Form.Group>

                <Button variant="primary" type="submit">
                  Buscar
                </Button>
              </Form>
            </Col>
          </Row>
          {showResults && (
            <Row>
              <Col>
                <ul>
                  <li>
                    {documentName}{" "}
                    <button
                      onClick={handleDownload}
                      type="button"
                      class="btn btn-primary"
                    >
                      <i class=" downloadDocs bi bi-arrow-bar-down"></i>
                    </button>
                  </li>
                </ul>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
