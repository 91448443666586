import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import Logo from '../images/completeLogo.png'
import Button from 'react-bootstrap/Button';
import ScrollToAnchor from '../helpers/scrolltoAncher';
import { Link } from 'react-router-dom';



const MainBanner = () => {
    return (
        <Container fluid >
            <Row>
                <Col className='fullH d-flex align-items-center justify-content-center' id='mainBanner' >
                    <Row id="mainBanner-elements">
                        <Col>
                            <Row className=' d-flex align-items-center justify-content-center mt-5'>
                                <Col xs={12} md={12} className='text-center mt-3 ' >
                                    <Image className='mainBannerLogo' src={Logo}></Image>

                                </Col>
                            </Row>
                            <Row className='d-flex align-items-center justify-content-center mt-4'>
                                <Col xs={12} md={12} className=' p-1 text-center text-light' >
                                    <p>
                                        Documentos legales fácil y rápido
                                    </p>
                                    <ScrollToAnchor />
                                    <Link className='nav-link' to="#mainDocuments"><Button className='secondaryColor' >Ver documentos</Button></Link>

                                </Col>
                            </Row>
                        </Col>
                    </Row>


                </Col>
            </Row>
        </Container>
    );




};


export default MainBanner;