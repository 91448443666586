import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const Modal_order_error_handler = (props) => {
  const handleClick = (e) => {
    if (e.target.classList.contains("dismiss")) {
      props.errorModal(false);
    }
  };
  let message = "";

  if (props.type === "Pendiente") {
    message =
      "una vez se confirme la transacción los documentos serán enviados al correo electronico proporcionado al momento de realizar el pedido.";
  } else if (
    props.type === "Abortada" ||
    props.type === "Transacción rechazada"
  ) {
    message =
      "se ha presentado un error al momento de realizar el pago por favor intentelo de nuevo mas tarde.";
  }

  return (
    <>
      <div className="overlay dismiss" onClick={handleClick}>
        <div
          className="modal show dismiss"
          style={{ display: "block", position: "middle" }}
        >
          <Modal.Dialog centered={true}>
            <Modal.Header className="dismiss">
              <Modal.Title>Notificación</Modal.Title>
              <span className="dismiss" onClick={handleClick}>
                X
              </span>
            </Modal.Header>

            <Modal.Body>
              <p>
                El estado de la transacción es el siguiente{" "}
                <strong>{props.type}</strong>, {message}
              </p>
            </Modal.Body>

            <Modal.Footer className="text-center">
              <Button
                className="dismiss"
                variant="success"
                onClick={handleClick}
              >
                Entendido
              </Button>
            </Modal.Footer>
          </Modal.Dialog>
        </div>
      </div>
    </>
  );
};

export default Modal_order_error_handler;
