/***TRANSITO**** */
/*
1-text
2-numner
3-textarea
4-email
5-date

*/
export const T_doc1 = [
  {
    name: "1_one",
    label: "1. Ciudad de residencia del peticionario ",
    type: "text",
    placeholder: "Ciudad",
    aid: {
      message:
        "Por favor completa el campo escribiendo el Municipio donde habita",
    },
    require: true,
  },
  {
    name: "5_two",
    label: "2. Fecha en la que presentarás la solicitud",
    type: "date",
    placeholder: "Fecha",
    aid: {
      message: "Por favor completa el campo con la fecha de tu solicitud ",
    },
    require: true,
  },
  {
    name: "1_three",
    label: "3.	Nombre del funcionario al que dirige la solicitud (OPCIONAL)",
    type: "text",
    placeholder: "Nombre funcionario",
    aid: {
      message:
        "Si desea agregar el nombre del funcionario, puede buscaren Google indicando el cargo y la ciudad.",
      image: "first",
    },
    require: false,
  },
  {
    name: "1_four",
    label:
      "4.	Cargo del funcionario al que dirige la solicitud, Ejemplo: Secreatio(a) de Hacienda",
    type: "text",
    placeholder: "Cargo",
    aid: { message: "Ejemplo: Secreatio(a) de Hacienda" },
    require: true,
  },
  {
    name: "1_five",
    label: "5.	Dirección de la entidad a la que dirige la solicitud (OPCIONAL)",
    type: "text",
    placeholder: "Dirección",
    aid: {
      message:
        "Si requiere relacionar el correo y/o la dirección de la entidad a la que se dirige, puede buscaren Google como el ejemplo de la imagen.",
      image: "second",
    },
    require: false,
  },
  {
    name: "4_entityEmail",
    label:
      "6. Correo electrónico de la entidad a la que dirige la solicitud (OPCIONAL)",
    type: "email",
    placeholder: "Correo electrónico de la entidad",
    aid: {
      message:
        "Si requiere relacionar el correo y/o la dirección de la entidad a la que se dirige, puede buscaren Google como el ejemplo de la imagen.",
      image: "second",
    },
    require: false,
  },
  {
    name: "1_entityCity",
    label: "7. Ciudad donde se ubica la entidad a la que dirige la solicitud",
    type: "text",
    placeholder: "Ciudad de la entidad",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_petitionerName",
    label: "8. Nombre completo del peticionario (quien firma la solicitud)",
    type: "text",
    placeholder: "Nombre completo",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_identificationType",
    label: "9. Tipo de identificación",
    type: "text",
    placeholder: "Tipo de identificación",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_identificationNumber",
    label: "10. Número de identificación del peticionario",
    type: "text",
    placeholder: "Número de identificación",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_petitionerRole",
    label:
      "11. ¿En qué calidad actúa el peticionario? Ejemplo: contribuyente, heredero, administrador, propietario.",
    type: "text",
    placeholder: "Calidad del peticionario",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_trafficTicketNumber",
    label: "12. Número del comparendo de tránsito – Foto de ejemplo",
    type: "text",
    placeholder: "Número del comparendo",
    aid: {
      message:
        "El número y la fecha de comparendo lo encontrará en el Acto Administrativo que lo declara infractor, o en el reporte del SIMIT indicando el número de cédula del sancionado en la página siguiente página web: https://fcm.org.co/simit/#/home-public",
      image: "tenth",
    },
    require: true,
  },
  {
    name: "5_trafficTicketDate",
    label: "13. Fecha del comparendo – Foto de ejemplo",
    type: "date",
    placeholder: "Seleccionar fecha",
    aid: {
      message:
        "El número y la fecha de comparendo lo encontrará en el Acto Administrativo que lo declara infractor, o en el reporte del SIMIT indicando el número de cédula del sancionado en la página siguiente página web: https://fcm.org.co/simit/#/home-public",
      image: "tenth",
    },
    require: true,
  },
  {
    name: "5_notificationEmail",
    label:
      "14. El correo electrónico en el que desea recibir la notificación de la respuesta",
    type: "email",
    placeholder: "Correo electrónico de notificación",
    aid: { message: "" },
    require: true,
  },
  {
    name: "notificationAddress",
    label:
      "15. La dirección física en la que desea recibir la notificación de la respuesta",
    type: "text",
    placeholder: "Dirección de notificación",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_petitionerPhoneNumber",
    label: "16. Número de teléfono del peticionario",
    type: "text",
    placeholder: "Número de teléfono",
    aid: { message: "" },
    require: true,
  },

  // Otras preguntas...
];

export const T_doc2 = [
  {
    name: "1_residenceCity",
    label: "1. Ciudad de residencia del peticionario (Municipio donde habita)",
    type: "text",
    placeholder: "Ciudad",
    aid: { message: "" },
    require: true,
  },
  {
    name: "5_applicationDate",
    label: "2. Fecha en la que se presentará la solicitud",
    type: "date",
    placeholder: "Seleccionar fecha",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_officialName",
    label: "3. Nombre del funcionario al que dirige la solicitud (OPCIONAL)",
    type: "text",
    placeholder: "Nombre del funcionario",
    aid: {
      message:
        "Si desea agregar el nombre del funcionario, puede buscaren Google indicando el cargo y la ciudad.",
      image: "first",
    },
    require: false,
  },
  {
    name: "1_officialPosition",
    label:
      "4.	Cargo del funcionario al que dirige la solicitud, Ejemplo: Secreatio(a) de Hacienda",
    type: "text",
    placeholder: "Cargo del funcionario",
    aid: { message: "Ejemplo: Secreatio(a) de Hacienda" },
    require: true,
  },
  {
    name: "1_entityAddress",
    label: "5. Dirección de la entidad a la que dirige la solicitud (OPCIONAL)",
    type: "text",
    placeholder: "Dirección de la entidad",
    aid: {
      message:
        "Si requiere relacionar el correo y/o la dirección de la entidad a la que se dirige, puede buscarlo en Google como el ejemplo de la imagen.",
      image: "second",
    },
    require: false,
  },
  {
    name: "4_entityEmail",
    label:
      "6. Correo electrónico de la entidad a la que dirige la solicitud (OPCIONAL)",
    type: "email",
    placeholder: "Correo electrónico de la entidad",
    aid: {
      message:
        "Si requiere relacionar el correo y/o la dirección de la entidad a la que se dirige, puede buscarlo en Google como el ejemplo de la imagen.",
      image: "second",
    },
    require: false,
  },
  {
    name: "1_entityCity",
    label: "7. Ciudad donde se ubica la entidad a la que dirige la solicitud",
    type: "text",
    placeholder: "Ciudad de la entidad",
    aid: { message: "" },
  },
  {
    name: "1_petitionerName",
    label: "8. Nombre completo del peticionario (quien firma la solicitud)",
    type: "text",
    placeholder: "Nombre completo",
    aid: { message: "" },
  },
  {
    name: "1_identificationType",
    label: "9. Tipo de identificación",
    type: "text",
    placeholder: "Tipo de identificación",
    aid: { message: "" },
  },
  {
    name: "1_identificationNumber",
    label: "10. Número de identificación del peticionario",
    type: "text",
    placeholder: "Número de identificación",
    aid: { message: "" },
  },

  {
    name: "1_petitionerRole",
    label:
      "11. ¿En qué calidad actúa el peticionario? Ejemplo: contribuyente, heredero, administrador, propietario.",
    type: "text",
    placeholder: "Calidad del peticionario",
    aid: { message: "" },
  },
  {
    name: "1_vehiclePlates",
    label: "12. Placas del vehículo objeto de la petición",
    type: "text",
    placeholder: "Placas del vehículo",
    aid: { message: "" },
  },
  {
    name: "4_notificationEmail",
    label:
      "13. El correo electrónico en el que desea recibir la notificación de la respuesta",
    type: "email",
    placeholder: "Correo electrónico para notificación",
    aid: { message: "" },
  },
  {
    name: "1_notificationAddress",
    label:
      "14. La dirección física en la que desea recibir la notificación de la respuesta",
    type: "text",
    placeholder: "Dirección para notificación",
    aid: { message: "" },
  },
  {
    name: "1_petitionerPhoneNumber",
    label: "15. Número de teléfono del peticionario",
    type: "text",
    placeholder: "Número de teléfono",
    aid: { message: "" },
  },
];
export const T_doc3 = [
  {
    name: "1_residenceCity",
    label: "1. Ciudad de residencia del peticionario (Municipio donde habita)",
    type: "text",
    placeholder: "Ciudad",
    aid: { message: "" },
    require: true,
  },
  {
    name: "5_applicationDate",
    label: "2. Fecha en la que se presentará la solicitud",
    type: "date",
    placeholder: "Seleccionar fecha",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_officialName",
    label: "3. Nombre del funcionario al que dirige la solicitud (OPCIONAL)",
    type: "text",
    placeholder: "Nombre del funcionario",
    aid: {
      message:
        "Si desea agregar el nombre del funcionario, puede buscarlo en Google indicando el cargo y la ciudad.",
      image: "first",
    },
    require: false,
  },
  {
    name: "1_officialPosition",
    label:
      "4.	Cargo del funcionario al que dirige la solicitud, Ejemplo: Secreatio(a) de Hacienda",
    type: "text",
    placeholder: "Cargo del funcionario",
    aid: { message: "Ejemplo: Secreatio(a) de Hacienda" },
    require: true,
  },
  {
    name: "1_entityAddress",
    label: "5. Dirección de la entidad a la que dirige la solicitud (OPCIONAL)",
    type: "text",
    placeholder: "Dirección de la entidad",
    aid: {
      message:
        "Si requiere relacionar el correo y/o la dirección de la entidad a la que se dirige, puede buscarlo en Google como el ejemplo de la imagen.",
      image: "second",
    },
    require: false,
  },
  {
    name: "5_entityEmail",
    label:
      "6. Correo electrónico de la entidad a la que dirige la solicitud (OPCIONAL)",
    type: "email",
    placeholder: "Correo electrónico de la entidad",
    aid: {
      message:
        "Si requiere relacionar el correo y/o la dirección de la entidad a la que se dirige, puede buscarlo en Google como el ejemplo de la imagen.",
      image: "second",
    },
    require: false,
  },
  {
    name: "1_entityCity",
    label: "7. Ciudad donde se ubica la entidad a la que dirige la solicitud",
    type: "text",
    placeholder: "Ciudad de la entidad",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_petitionerName",
    label: "8. Nombre completo del peticionario (quien firma la solicitud)",
    type: "text",
    placeholder: "Nombre completo",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_identificationType",
    label: "9. Tipo de identificación",
    type: "text",
    placeholder: "Tipo de identificación",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_identificationNumber",
    label: "10. Número de identificación del peticionario",
    type: "text",
    placeholder: "Número de identificación",
    aid: { message: "" },
    require: true,
  },

  {
    name: "1_petitionerRole",
    label:
      "11. ¿En qué calidad actúa el peticionario? Ejemplo: contribuyente, heredero, administrador, propietario.",
    type: "text",
    placeholder: "Calidad del peticionario",
    aid: { message: "" },
    require: true,
  },
  {
    name: "2_resolutionNumber",
    label: "12. Número de la Resolución sanción ",
    type: "number",
    placeholder: "Numero de resolucion",
    aid: {
      message:
        "El número de Resolución sanción lo encontrará en la parte superior del Acto Administrativo que lo declara infractor, o en el reporte del SIMIT indicando el número de cédula del sancionado en la página siguiente página web: https://fcm.org.co/simit/#/home-public",
      image: "eighth",
    },
    requite: true,
  },
  {
    name: "5_resolutionDate",
    label: "13.Fecha de la resolución sanción ",
    type: "date",
    placeholder: "Fecha",
    aid: {
      message:
        ": La fecha de la Resolución sanción lo encontrará en la parte superior del Acto Administrativo que lo declara infractor, o en el reporte del SIMIT indicando el número de cédula del sancionado en la página siguiente página web: https://fcm.org.co/simit/#/home-public",
      image: "ninth",
    },
    requite: true,
  },
  {
    name: "2_ticketNumber",
    label: "14. Número del comparendo de tránsito ",
    type: "number",
    placeholder: "Número del comparendo",
    aid: {
      message:
        "El número y la fecha de comparendo lo encontrará en el Acto Administrativo que lo declara infractor, o en el reporte del SIMIT indicando el número de cédula del sancionado en la página siguiente página web: https://fcm.org.co/simit/#/home-public",
      image: "tenth",
    },
    requite: true,
  },
  {
    name: "5_ticketDate",
    label: "15.Fecha del comparendo ",
    type: "date",
    placeholder: "Fecha",
    aid: {
      message:
        "El número y la fecha de comparendo lo encontrará en el Acto Administrativo que lo declara infractor, o en el reporte del SIMIT indicando el número de cédula del sancionado en la página siguiente página web: https://fcm.org.co/simit/#/home-public",
      image: "tenth",
    },
    requite: true,
  },
  {
    name: "1_punishedName",
    label: "16.Nombre completo del sancionado",
    type: "text",
    placeholder: "Nombre",
    aid: { message: "" },
    requite: true,
  },
  {
    name: "1_punishedID",
    label: "17.	Tipo de documento de identidad del sancionado ",
    type: "text",
    placeholder: "Tipo documento",
    aid: {
      message:
        "Ejemplo: cédula de ciudadanía, pasaporte, tarjeta de identidad, cédula de extranjería.",
    },
    requite: true,
  },
  {
    name: "2_punishedIdnumber",
    label: "18.Número del documento de identificación del sancionado. ",
    type: "number",
    placeholder: "Numero documento",
    aid: { message: "" },
    requite: true,
  },
  {
    name: "2_sanctionAmount",
    label: "19.Valor de la sanción en números",
    type: "number",
    placeholder: "valor sanción",
    aid: { message: "Ejemplo: $3.686.422" },
    requite: true,
  },
  {
    name: "2_paymentResolutionNumber",
    label: "20.	Número de la Resolución de Mandamiento de pago ",
    type: "number",
    placeholder: "Número resolución",
    aid: {
      message:
        "El número y la fecha del Mandamiento de pago lo encontrará en el Acto Administrativo con dicho título, o en el reporte del SIMIT indicando el número de cédula del sancionado en la página siguiente página web: https://fcm.org.co/simit/#/home-public",
      image: "eleventh",
    },
    requite: true,
  },
  {
    name: "5_paymentResolutionDate",
    label: "21.	Fecha de la Resolución de Mandamiento de pago ",
    type: "date",
    placeholder: "Fecha",
    aid: {
      message:
        "El número y la fecha del Mandamiento de pago lo encontrará en el Acto Administrativo con dicho título, o en el reporte del SIMIT indicando el número de cédula del sancionado en la página siguiente página web: https://fcm.org.co/simit/#/home-public",
      image: "eleventh",
    },
    requite: true,
  },
  {
    name: "1_timeBetweendoings",
    label:
      "22.	Indicar el tiempo que ha transcurrido desde el momento de los hechos. Años, meses, días ",
    type: "text",
    placeholder: "Ejemplo: 4 años 5 meses 30 días",
    aid: { message: "Ejemplo: 1 año, tres meses y 10 días." },
    requite: true,
  },
  {
    name: "5_emailAnswer",
    label:
      "23.	El correo electrónico en el que desea recibir la notificación de la respuesta.",
    type: "email",
    placeholder: "Correo Electronico",
    aid: { message: "" },
    requite: true,
  },
  {
    name: "1_addressAnswer",
    label:
      "24.	La dirección física en la que desea recibir la notificación de la respuesta. ",
    type: "text",
    placeholder: "Dirección",
    aid: { message: "" },
    requite: true,
  },
  {
    name: "2_petitionerPhoneNumber",
    label: "25.	Número de teléfono del peticionario.",
    type: "number",
    placeholder: "Teléfono",
    aid: { message: "" },
    requite: true,
  },
];

export const T_doc4 = [
  {
    name: "1_residenceCity",
    label: "1. Ciudad de residencia del peticionario (Municipio donde habita)",
    type: "text",
    placeholder: "Ciudad",
    aid: { message: "" },
    require: true,
  },
  {
    name: "5_applicationDate",
    label: "2. Fecha en la que se presentará la solicitud",
    type: "date",
    placeholder: "Seleccionar fecha",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_officialName",
    label: "3. Nombre del funcionario al que dirige la solicitud (OPCIONAL)",
    type: "text",
    placeholder: "Nombre del funcionario",
    aid: {
      message:
        "Si desea agregar el nombre del funcionario, puede buscarlo en Google indicando el cargo y la ciudad.",
      image: "first",
    },
    require: false,
  },
  {
    name: "1_officialPosition",
    label:
      "4.	Cargo del funcionario al que dirige la solicitud, Ejemplo: Secreatio(a) de Hacienda",
    type: "text",
    placeholder: "Cargo del funcionario",
    aid: { message: "Ejemplo: Secreatio(a) de Hacienda" },
    require: true,
  },
  {
    name: "1_entityAddress",
    label: "5. Dirección de la entidad a la que dirige la solicitud (OPCIONAL)",
    type: "text",
    placeholder: "Dirección de la entidad",
    aid: {
      message:
        "Si requiere relacionar el correo y/o la dirección de la entidad a la que se dirige, puede buscarlo en Google como el ejemplo de la imagen.",
      image: "second",
    },
    require: false,
  },
  {
    name: "4_entityEmail",
    label:
      "6. Correo electrónico de la entidad a la que dirige la solicitud (OPCIONAL)",
    type: "email",
    placeholder: "Correo electrónico de la entidad",
    aid: {
      message:
        "Si requiere relacionar el correo y/o la dirección de la entidad a la que se dirige, puede buscarlo en Google como el ejemplo de la imagen.",
      image: "second",
    },
    require: false,
  },
  {
    name: "1_entityCity",
    label: "7. Ciudad donde se ubica la entidad a la que dirige la solicitud",
    type: "text",
    placeholder: "Ciudad de la entidad",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_petitionerName",
    label: "8. Nombre completo del peticionario (quien firma la solicitud)",
    type: "text",
    placeholder: "Nombre completo",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_identificationType",
    label: "9. Tipo de identificación",
    type: "text",
    placeholder: "Tipo de identificación",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_identificationNumber",
    label: "10. Número de identificación del peticionario",
    type: "text",
    placeholder: "Número de identificación",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_suspensionResolutionNumber",
    label: "11. Número de la Resolución sanción",
    type: "text",
    placeholder: "Número de la Resolución",
    aid: {
      message:
        "El número de Resolución sanción lo encontrará en la parte superior del Acto Administrativo que lo declara infractor, o en el reporte del SIMIT indicando el número de cédula del sancionado en la página siguiente página web: https://fcm.org.co/simit/#/home-public",
      image: "eighth",
    },
    require: true,
  },
  {
    name: "5_suspensionResolutionDate",
    label: "12. Fecha de la resolución sanción",
    type: "date",
    placeholder: "Seleccionar fecha",
    aid: {
      message:
        ": La fecha de la Resolución sanción lo encontrará en la parte superior del Acto Administrativo que lo declara infractor, o en el reporte del SIMIT indicando el número de cédula del sancionado en la página siguiente página web: https://fcm.org.co/simit/#/home-public",
      image: "ninth",
    },
    require: true,
  },
  {
    name: "1_trafficTicketNumber",
    label: "13. Número del comparendo de tránsito",
    type: "text",
    placeholder: "Número del comparendo",
    aid: {
      message:
        "El número y la fecha de comparendo lo encontrará en el Acto Administrativo que lo declara infractor, o en el reporte del SIMIT indicando el número de cédula del sancionado en la página siguiente página web: https://fcm.org.co/simit/#/home-public",
      image: "tenth",
    },
    require: true,
  },
  {
    name: "5_trafficTicketDate",
    label: "14. Fecha del comparendo",
    type: "date",
    placeholder: "Seleccionar fecha",
    aid: {
      message:
        "El número y la fecha de comparendo lo encontrará en el Acto Administrativo que lo declara infractor, o en el reporte del SIMIT indicando el número de cédula del sancionado en la página siguiente página web: https://fcm.org.co/simit/#/home-public",
      image: "tenth",
    },
    require: true,
  },
  {
    name: "1_sanctionAmount",
    label: "15. Valor de la sanción en números",
    type: "text",
    placeholder: "Valor de la sanción",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_paymentOrderResolutionNumber",
    label: "16. Número de la Resolución de Mandamiento de pago",
    type: "text",
    placeholder: "Número de la Resolución de Mandamiento de pago",
    aid: {
      message:
        "El número y la fecha del Mandamiento de pago los encontrará en la resolución que ordena el pago de la obligación.",
      image: "sixth",
    },
    require: true,
  },
  {
    name: "5_paymentOrderResolutionDate",
    label: "17. Fecha de la Resolución de Mandamiento de pago",
    type: "date",
    placeholder: "Seleccionar fecha",
    aid: {
      message:
        "El número y la fecha del Mandamiento de pago los encontrará en la resolución que ordena el pago de la obligación.",
      image: "sixth",
    },
    require: true,
  },
  {
    name: "1_timeElapsed",
    label:
      "18. Indicar el tiempo que ha transcurrido desde el momento de los hechos",
    type: "text",
    placeholder: "Tiempo transcurrido en días",
    aid: { message: "" },
    require: true,
  },
  {
    name: "4_notificationEmail",
    label:
      "19. El correo electrónico en el que desea recibir la notificación de la respuesta",
    type: "email",
    placeholder: "Correo electrónico para notificación",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_notificationAddress",
    label:
      "20. La dirección física en la que desea recibir la notificación de la respuesta",
    type: "text",
    placeholder: "Dirección para notificación",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_petitionerPhoneNumber",
    label: "21. Número de teléfono del peticionario",
    type: "text",
    placeholder: "Número de teléfono",
    aid: { message: "" },
    require: true,
  },
];
export const T_doc5 = [
  {
    name: "1_residenceCity",
    label: "1. Ciudad de residencia del peticionario (Municipio donde habita)",
    type: "text",
    placeholder: "Ciudad",
    aid: { message: "" },
    require: true,
  },
  {
    name: "5_applicationDate",
    label: "2. Fecha en la que se presentará la solicitud",
    type: "date",
    placeholder: "Seleccionar fecha",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_officialName",
    label: "3. Nombre del funcionario al que dirige la solicitud (OPCIONAL)",
    type: "text",
    placeholder: "Nombre del funcionario",
    aid: {
      message:
        "Si desea agregar el nombre del funcionario, puede buscarlo en Google indicando el cargo y la ciudad.",
      image: "first",
    },
    require: false,
  },
  {
    name: "1_officialPosition",
    label:
      "4.	Cargo del funcionario al que dirige la solicitud, Ejemplo: Secreatio(a) de Hacienda",
    type: "text",
    placeholder: "Cargo del funcionario",
    aid: { message: "Ejemplo: Secreatio(a) de Hacienda" },
    require: true,
  },
  {
    name: "1_entityAddress",
    label: "5. Dirección de la entidad a la que dirige la solicitud (OPCIONAL)",
    type: "text",
    placeholder: "Dirección de la entidad",
    aid: {
      message:
        "Si requiere relacionar el correo y/o la dirección de la entidad a la que se dirige, puede buscarlo en Google como el ejemplo de la imagen.",
      image: "second",
    },
    require: false,
  },
  {
    name: "4_entityEmail",
    label:
      "6. Correo electrónico de la entidad a la que dirige la solicitud (OPCIONAL)",
    type: "email",
    placeholder: "Correo electrónico de la entidad",
    aid: {
      message:
        "Si requiere relacionar el correo y/o la dirección de la entidad a la que se dirige, puede buscarlo en Google como el ejemplo de la imagen.",
      image: "second",
    },
    require: false,
  },
  {
    name: "1_entityCity",
    label: "7. Ciudad donde se ubica la entidad a la que dirige la solicitud",
    type: "text",
    placeholder: "Ciudad de la entidad",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_petitionerName",
    label:
      "8. Nombre completo del peticionario y propietario del vehículo (quien firma la solicitud)",
    type: "text",
    placeholder: "Nombre completo",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_identificationType",
    label: "9. Tipo de identificación",
    type: "text",
    placeholder: "Tipo de identificación",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_identificationNumber",
    label: "10. Número de identificación del peticionario",
    type: "text",
    placeholder: "Número de identificación",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_vehicleLicensePlates",
    label: "11. Placas del vehículo que generó el impuesto",
    type: "text",
    placeholder: "Placas del vehículo",
    aid: { message: "" },
    require: true,
  },
  {
    name: "4_notificationEmail",
    label:
      "12. El correo electrónico en el que desea recibir la notificación de la respuesta",
    type: "email",
    placeholder: "Correo electrónico para notificación",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_notificationAddress",
    label:
      "13. La dirección física en la que desea recibir la notificación de la respuesta",
    type: "text",
    placeholder: "Dirección para notificación",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_petitionerPhoneNumber",
    label: "14. Número de teléfono del peticionario",
    type: "text",
    placeholder: "Número de teléfono",
    aid: { message: "" },
    require: true,
  },
];

export const T_doc6 = [
  {
    name: "1_residenceCity",
    label: "1. Ciudad de residencia del peticionario (Municipio donde habita)",
    type: "text",
    placeholder: "Ciudad",
    aid: { message: "" },
    require: true,
  },
  {
    name: "5_applicationDate",
    label: "2. Fecha en la que se presentará la solicitud",
    type: "date",
    placeholder: "Seleccionar fecha",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_officialName",
    label: "3. Nombre del funcionario al que dirige la solicitud (OPCIONAL)",
    type: "text",
    placeholder: "Nombre del funcionario",
    aid: {
      message:
        "Si desea agregar el nombre del funcionario, puede buscarlo en Google indicando el cargo y la ciudad.",
      image: "first",
    },
    require: false,
  },
  {
    name: "1_officialPosition",
    label:
      "4.	Cargo del funcionario al que dirige la solicitud, Ejemplo: Secreatio(a) de Hacienda",
    type: "text",
    placeholder: "Cargo del funcionario",
    aid: { message: "Ejemplo: Secreatio(a) de Hacienda" },
    require: true,
  },
  {
    name: "1_entityAddress",
    label: "5. Dirección de la entidad a la que dirige la solicitud (OPCIONAL)",
    type: "text",
    placeholder: "Dirección de la entidad",
    aid: {
      message:
        "Si requiere relacionar el correo y/o la dirección de la entidad a la que se dirige, puede buscarlo en Google como el ejemplo de la imagen.",
      image: "second",
    },
    require: false,
  },
  {
    name: "4_entityEmail",
    label:
      "6. Correo electrónico de la entidad a la que dirige la solicitud (OPCIONAL)",
    type: "email",
    placeholder: "Correo electrónico de la entidad",
    aid: {
      message:
        "Si requiere relacionar el correo y/o la dirección de la entidad a la que se dirige, puede buscarlo en Google como el ejemplo de la imagen.",
      image: "second",
    },
    require: false,
  },
  {
    name: "1_entityCity",
    label: "7. Ciudad donde se ubica la entidad a la que dirige la solicitud",
    type: "text",
    placeholder: "Ciudad de la entidad",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_petitionerName",
    label: "8. Nombre completo del peticionario (quien firma la solicitud)",
    type: "text",
    placeholder: "Nombre completo",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_identificationType",
    label: "9. Tipo de identificación",
    type: "text",
    placeholder: "Tipo de identificación",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_identificationNumber",
    label: "10. Número de identificación del peticionario",
    type: "text",
    placeholder: "Número de identificación",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_sanctionResolutionNumber",
    label: "11. Número de la Resolución sanción",
    type: "text",
    placeholder: "Número de la Resolución",
    aid: {
      message:
        "El número de Resolución sanción lo encontrará en la parte superior del Acto Administrativo que lo declara infractor, o en el reporte del SIMIT indicando el número de cédula del sancionado en la página siguiente página web: https://fcm.org.co/simit/#/home-public",
      image: "eighth",
    },
    require: true,
  },
  {
    name: "5_sanctionResolutionDate",
    label: "12. Fecha de la resolución sanción",
    type: "date",
    placeholder: "Seleccionar fecha",
    aid: {
      message:
        ": La fecha de la Resolución sanción lo encontrará en la parte superior del Acto Administrativo que lo declara infractor, o en el reporte del SIMIT indicando el número de cédula del sancionado en la página siguiente página web: https://fcm.org.co/simit/#/home-public",
      image: "ninth",
    },
    require: true,
  },
  {
    name: "1_trafficTicketNumber",
    label: "13. Número del comparendo de tránsito",
    type: "text",
    placeholder: "Número del comparendo",
    aid: {
      message:
        "El número y la fecha de comparendo lo encontrará en el Acto Administrativo que lo declara infractor, o en el reporte del SIMIT indicando el número de cédula del sancionado en la página siguiente página web: https://fcm.org.co/simit/#/home-public",
      image: "tenth",
    },
    require: true,
  },
  {
    name: "5_trafficTicketDate",
    label: "14. Fecha del comparendo",
    type: "date",
    placeholder: "Seleccionar fecha",
    aid: {
      message:
        "El número y la fecha de comparendo lo encontrará en el Acto Administrativo que lo declara infractor, o en el reporte del SIMIT indicando el número de cédula del sancionado en la página siguiente página web: https://fcm.org.co/simit/#/home-public",
      image: "tenth",
    },
    require: true,
  },
  {
    name: "1_sanctionAmount",
    label: "15. Valor de la sanción en números",
    type: "text",
    placeholder: "Valor de la sanción",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_timeElapsed",
    label:
      "16. Indicar el tiempo que ha transcurrido desde el momento de los hechos",
    type: "text",
    placeholder: "Tiempo transcurrido en días",
    aid: { message: "" },
    require: true,
  },
  {
    name: "4_notificationEmail",
    label:
      "17. El correo electrónico en el que desea recibir la notificación de la respuesta",
    type: "email",
    placeholder: "Correo electrónico para notificación",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_notificationAddress",
    label:
      "18. La dirección física en la que desea recibir la notificación de la respuesta",
    type: "text",
    placeholder: "Dirección para notificación",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_petitionerPhoneNumber",
    label: "19. Número de teléfono del peticionario",
    type: "text",
    placeholder: "Número de teléfono",
    aid: { message: "" },
    require: true,
  },
];

/************PREDIAL******************** */
export const P_doc1 = [
  {
    name: "1_residenceCity",
    label: "1. Ciudad de residencia del peticionario (Municipio donde habita)",
    type: "text",
    placeholder: "Ciudad",
    aid: { message: "" },
    require: true,
  },
  {
    name: "5_applicationDate",
    label: "2. Fecha en la que se presentará la solicitud",
    type: "date",
    placeholder: "Seleccionar fecha",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_officialName",
    label: "3. Nombre del funcionario al que dirige la solicitud (OPCIONAL)",
    type: "text",
    placeholder: "Nombre del funcionario",
    aid: {
      message:
        "Si desea agregar el nombre del funcionario, puede buscarlo en Google indicando el cargo y la ciudad.",
      image: "first",
    },
    require: false,
  },
  {
    name: "1_officialPosition",
    label:
      "4.	Cargo del funcionario al que dirige la solicitud, Ejemplo: Secreatio(a) de Hacienda",
    type: "text",
    placeholder: "Cargo del funcionario",
    aid: { message: "Ejemplo: Secreatio(a) de Hacienda" },
    require: true,
  },
  {
    name: "1_entityAddress",
    label: "5. Dirección de la entidad a la que dirige la solicitud (OPCIONAL)",
    type: "text",
    placeholder: "Dirección de la entidad",
    aid: {
      message:
        "Si requiere relacionar el correo y/o la dirección de la entidad a la que se dirige, puede buscarlo en Google como el ejemplo de la imagen.",
      image: "second",
    },
    require: false,
  },
  {
    name: "4_entityEmail",
    label:
      "6. Correo electrónico de la entidad a la que dirige la solicitud (OPCIONAL)",
    type: "email",
    placeholder: "Correo electrónico de la entidad",
    aid: {
      message:
        "Si requiere relacionar el correo y/o la dirección de la entidad a la que se dirige, puede buscarlo en Google como el ejemplo de la imagen.",
      image: "second",
    },
    require: false,
  },
  {
    name: "1_entityCity",
    label: "7. Ciudad donde se ubica la entidad a la que dirige la solicitud",
    type: "text",
    placeholder: "Ciudad de la entidad",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_petitionerName",
    label: "8. Nombre completo del peticionario (quien firma la solicitud)",
    type: "text",
    placeholder: "Nombre completo",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_identificationType",
    label: "9. Tipo de identificación",
    type: "text",
    placeholder: "Tipo de identificación",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_identificationNumber",
    label: "10. Número de identificación del peticionario",
    type: "text",
    placeholder: "Número de identificación",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_petitionerRole",
    label:
      "11. ¿En qué calidad actúa el peticionario? Ejemplo: contribuyente, heredero, administrador, propietario.",
    type: "text",
    placeholder: "Calidad del peticionario",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_cadastralNumber",
    label: "12. Número de ficha catastral – Foto de ejemplo",
    type: "text",
    placeholder: "Número de ficha catastral",
    aid: {
      message:
        "El número de la ficha catastral del inmueble la puede encontrar en la factura del impuesto predial.",
      image: "third",
    },
    require: true,
  },
  {
    name: "4_notificationEmail",
    label:
      "13. El correo electrónico en el que desea recibir la notificación de la respuesta",
    type: "email",
    placeholder: "Correo electrónico de notificación",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_notificationAddress",
    label:
      "14. La dirección física en la que desea recibir la notificación de la respuesta",
    type: "text",
    placeholder: "Dirección de notificación",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_petitionerPhoneNumber",
    label: "15. Número de teléfono del peticionario",
    type: "text",
    placeholder: "Número de teléfono",
    aid: { message: "" },
    require: true,
  },
];

export const P_doc2 = [
  {
    name: "1_residenceCity",
    label: "1. Ciudad de residencia del peticionario (Municipio donde habita)",
    type: "text",
    placeholder: "Ciudad",
    aid: { message: "" },
    require: true,
  },
  {
    name: "5_applicationDate",
    label: "2. Fecha en la que se presentará la solicitud",
    type: "date",
    placeholder: "Seleccionar fecha",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_officialName",
    label: "3. Nombre del funcionario al que dirige la solicitud (OPCIONAL)",
    type: "text",
    placeholder: "Nombre del funcionario",
    aid: {
      message:
        "Si desea agregar el nombre del funcionario, puede buscarlo en Google indicando el cargo y la ciudad.",
      image: "first",
    },
    require: false,
  },
  {
    name: "1_officialPosition",
    label:
      "4.	Cargo del funcionario al que dirige la solicitud, Ejemplo: Secreatio(a) de Hacienda",
    type: "text",
    placeholder: "Cargo del funcionario",
    aid: { message: "Ejemplo: Secreatio(a) de Hacienda" },
    require: true,
  },
  {
    name: "1_entityAddress",
    label: "5. Dirección de la entidad a la que dirige la solicitud (OPCIONAL)",
    type: "text",
    placeholder: "Dirección de la entidad",
    aid: {
      message:
        "Si requiere relacionar el correo y/o la dirección de la entidad a la que se dirige, puede buscarlo en Google como el ejemplo de la imagen.",
      image: "second",
    },
    require: false,
  },
  {
    name: "4_entityEmail",
    label:
      "6. Correo electrónico de la entidad a la que dirige la solicitud (OPCIONAL)",
    type: "email",
    placeholder: "Correo electrónico de la entidad",
    aid: {
      message:
        "Si requiere relacionar el correo y/o la dirección de la entidad a la que se dirige, puede buscarlo en Google como el ejemplo de la imagen.",
      image: "second",
    },
    require: false,
  },
  {
    name: "1_entityCity",
    label: "7. Ciudad donde se ubica la entidad a la que dirige la solicitud",
    type: "text",
    placeholder: "Ciudad de la entidad",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_petitionerName",
    label: "8. Nombre completo del peticionario (quien firma la solicitud)",
    type: "text",
    placeholder: "Nombre completo",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_identificationType",
    label: "9. Tipo de identificación",
    type: "text",
    placeholder: "Tipo de identificación",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_identificationNumber",
    label: "10. Número de identificación del peticionario",
    type: "text",
    placeholder: "Número de identificación",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_petitionerRole",
    label:
      "11. ¿En qué calidad actúa el peticionario? Ejemplo: contribuyente, heredero, poseedor, propietario.",
    type: "text",
    placeholder: "Calidad del peticionario",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_cadastralNumber",
    label: "12. Número de ficha catastral del bien inmueble – Foto de ejemplo",
    type: "text",
    placeholder: "Número de ficha catastral",
    aid: {
      message:
        "El número de la ficha catastral del inmueble la puede encontrar en la factura del impuesto predial.",
      image: "third",
    },
    require: true,
  },
  {
    name: "1_propertyOwnerName",
    label: "13. Nombre del titular del cobro o propietario del bien inmueble",
    type: "text",
    placeholder: "Nombre del propietario",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_liquidationNumber",
    label:
      "14. Número de liquidación o título ejecutivo en el que se declara moroso al contribuyente – Foto de ejemplo",
    type: "text",
    placeholder: "Número de liquidación o título ejecutivo",
    aid: {
      message:
        "El número y la fecha de la liquidación la puede encontrar en la parte superior de la liquidación oficial o resolución que determina el valor del impuesto predial.",
      image: "fourth",
    },
    require: true,
  },
  {
    name: "5_liquidationDate",
    label: "15. Fecha de la liquidación oficial o título ejecutivo",
    type: "date",
    placeholder: "Seleccionar fecha",
    aid: {
      message:
        "El número y la fecha de la liquidación la puede encontrar en la parte superior de la liquidación oficial o resolución que determina el valor del impuesto predial.",
      image: "fourth",
    },
    require: true,
  },
  {
    name: "1_liquidationValue",
    label:
      "16. Valor de la liquidación oficial en números. Ejemplo: $20.456.214 – Foto de ejemplo",
    type: "text",
    placeholder: "Valor de la liquidación",
    aid: {
      message:
        "El valor del impuesto predial lo encontrará en el resuelve de la resolución que determina el valor del impuesto predial.",
      image: "fifth",
    },
    require: true,
  },
  {
    name: "1_notificationMethod",
    label:
      "17. Indicar la forma como fue notificada la liquidación. Ejemplo: de forma personal, por página web de la administración o por correo certificado. Si la liquidación oficial no fue notificada, por favor indícalo – Foto de ejemplo",
    type: "text",
    placeholder: "Método de notificación",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_paymentOrderNumber",
    label:
      "18. Número de la resolución de mandamiento de pago – Foto de ejemplo",
    type: "text",
    placeholder: "Número de resolución de mandamiento de pago",
    aid: {
      message:
        "El número y la fecha del Mandamiento de pago los encontrará en la resolución que ordena el pago de la obligación.",
      image: "sixth",
    },
    require: true,
  },
  {
    name: "5_paymentOrderDate",
    label:
      "19. Fecha de la resolución de mandamiento de pago – Foto de ejemplo",
    type: "date",
    placeholder: "Seleccionar fecha",
    aid: {
      message:
        "El número y la fecha del Mandamiento de pago los encontrará en la resolución que ordena el pago de la obligación.",
      image: "sixth",
    },
    require: true,
  },
  {
    name: "4_notificationEmail",
    label:
      "20. El correo electrónico en el que desea recibir la notificación de la respuesta",
    type: "email",
    placeholder: "Correo electrónico para notificación",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_notificationAddress",
    label:
      "21. La dirección física en la que desea recibir la notificación de la respuesta",
    type: "text",
    placeholder: "Dirección para notificación",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_petitionerPhoneNumber",
    label: "22. Número de teléfono del peticionario",
    type: "text",
    placeholder: "Número de teléfono",
    aid: { message: "" },
    require: true,
  },
];

export const P_doc3 = [
  {
    name: "1_residenceCity",
    label: "1. Ciudad de residencia del peticionario (Municipio donde habita)",
    type: "text",
    placeholder: "Ciudad",
    aid: { message: "" },
    require: true,
  },
  {
    name: "5_applicationDate",
    label: "2. Fecha en la que se presentará la solicitud",
    type: "date",
    placeholder: "Seleccionar fecha",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_officialName",
    label: "3. Nombre del funcionario al que dirige la solicitud (OPCIONAL)",
    type: "text",
    placeholder: "Nombre del funcionario",
    aid: {
      message:
        "Si desea agregar el nombre del funcionario, puede buscarlo en Google indicando el cargo y la ciudad.",
      image: "first",
    },
    require: false,
  },
  {
    name: "1_officialPosition",
    label:
      "4.	Cargo del funcionario al que dirige la solicitud, Ejemplo: Secreatio(a) de Hacienda",
    type: "text",
    placeholder: "Cargo del funcionario",
    aid: { message: "Ejemplo: Secreatio(a) de Hacienda" },
    require: true,
  },
  {
    name: "1_entityAddress",
    label: "5. Dirección de la entidad a la que dirige la solicitud (OPCIONAL)",
    type: "text",
    placeholder: "Dirección de la entidad",
    aid: {
      message:
        "Si requiere relacionar el correo y/o la dirección de la entidad a la que se dirige, puede buscarlo en Google como el ejemplo de la imagen.",
      image: "second",
    },
    require: false,
  },
  {
    name: "5_entityEmail",
    label:
      "6. Correo electrónico de la entidad a la que dirige la solicitud (OPCIONAL)",
    type: "email",
    placeholder: "Correo electrónico de la entidad",
    aid: {
      message:
        "Si requiere relacionar el correo y/o la dirección de la entidad a la que se dirige, puede buscarlo Google como el ejemplo de la imagen.",
      image: "second",
    },
    require: false,
  },
  {
    name: "1_entityCity",
    label: "7. Ciudad donde se ubica la entidad a la que dirige la solicitud",
    type: "text",
    placeholder: "Ciudad de la entidad",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_petitionerName",
    label: "8. Nombre completo del peticionario (quien firma la solicitud)",
    type: "text",
    placeholder: "Nombre completo",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_identificationType",
    label: "9. Tipo de identificación",
    type: "text",
    placeholder: "Tipo de identificación",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_identificationNumber",
    label: "10. Número de identificación del peticionario",
    type: "text",
    placeholder: "Número de identificación",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_petitionerRole",
    label:
      "11. ¿En qué calidad actúa el peticionario? Ejemplo: contribuyente, heredero, administrador, propietario.",
    type: "text",
    placeholder: "Calidad del peticionario",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_cadastralNumber",
    label: "12. Número de ficha catastral del bien inmueble – Foto de ejemplo",
    type: "text",
    placeholder: "Número de ficha catastral",
    aid: {
      message:
        "El número de la ficha catastral del inmueble la puede encontrar en la factura del impuesto predial.",
      image: "third",
    },
    require: true,
  },
  {
    name: "1_taxValidity",
    label:
      "13. Indicar la vigencia inicial y la final que se cobran en el impuesto. Ejemplo: 2010-2023",
    type: "text",
    placeholder: "Vigencia del impuesto",
    aid: {
      message:
        "La vigencia inicial y final que se cobran en el impuesto la encontrará en el resuelve del título ejecutivo o en la factura del impuesto predial.",
      image: "seventh",
    },
    require: true,
  },
  {
    name: "4_notificationEmail",
    label:
      "14. El correo electrónico en el que desea recibir la notificación de la respuesta",
    type: "email",
    placeholder: "Correo electrónico para notificación",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_notificationAddress",
    label:
      "15. La dirección física en la que desea recibir la notificación de la respuesta",
    type: "text",
    placeholder: "Dirección para notificación",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_petitionerPhoneNumber",
    label: "16. Número de teléfono del peticionario",
    type: "text",
    placeholder: "Número de teléfono",
    aid: { message: "" },
    require: true,
  },
];

/*************INDUSTRIA Y COMERCIO**********+ */

export const I_doc1 = [
  {
    name: "1_residenceCity",
    label: "1. Ciudad de residencia del peticionario (Municipio donde habita)",
    type: "text",
    placeholder: "Ciudad",
    aid: { message: "" },
    require: true,
  },
  {
    name: "5_applicationDate",
    label: "2. Fecha en la que se presentará la solicitud",
    type: "date",
    placeholder: "Seleccionar fecha",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_officialName",
    label: "3. Nombre del funcionario al que dirige la solicitud (OPCIONAL)",
    type: "text",
    placeholder: "Nombre del funcionario",
    aid: {
      message:
        "Si desea agregar el nombre del funcionario, puede buscarlo en Google indicando el cargo y la ciudad.",
      image: "first",
    },
    require: false,
  },
  {
    name: "1_officialPosition",
    label:
      "4.	Cargo del funcionario al que dirige la solicitud, Ejemplo: Secreatio(a) de Hacienda",
    type: "text",
    placeholder: "Cargo del funcionario",
    aid: { message: "Ejemplo: Secreatio(a) de Hacienda" },
    require: true,
  },
  {
    name: "1_entityAddress",
    label: "5. Dirección de la entidad a la que dirige la solicitud (OPCIONAL)",
    type: "text",
    placeholder: "Dirección de la entidad",
    aid: {
      message:
        "Si requiere relacionar el correo y/o la dirección de la entidad a la que se dirige, puede buscaren Google como el ejemplo de la imagen.",
      image: "second",
    },
    require: false,
  },
  {
    name: "4_entityEmail",
    label:
      "6. Correo electrónico de la entidad a la que dirige la solicitud (OPCIONAL)",
    type: "email",
    placeholder: "Correo electrónico de la entidad",
    aid: {
      message:
        "Si requiere relacionar el correo y/o la dirección de la entidad a la que se dirige, puede buscaren Google como el ejemplo de la imagen.",
      image: "second",
    },
    require: false,
  },
  {
    name: "1_entityCity",
    label: "7. Ciudad donde se ubica la entidad a la que dirige la solicitud",
    type: "text",
    placeholder: "Ciudad de la entidad",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_petitionerName",
    label: "8. Nombre completo del peticionario (quien firma la solicitud)",
    type: "text",
    placeholder: "Nombre completo",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_identificationType",
    label: "9. Tipo de identificación",
    type: "text",
    placeholder: "Tipo de identificación",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_identificationNumber",
    label: "10. Número de identificación del peticionario",
    type: "text",
    placeholder: "Número de identificación",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_establishmentName",
    label: "11. Nombre del establecimiento de comercio objeto de la petición",
    type: "text",
    placeholder: "Nombre del establecimiento de comercio",
    aid: { message: "" },
    require: true,
  },
  {
    name: "4_notificationEmail",
    label:
      "12. El correo electrónico en el que desea recibir la notificación de la respuesta",
    type: "email",
    placeholder: "Correo electrónico para notificación",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_notificationAddress",
    label:
      "13. La dirección física en la que desea recibir la notificación de la respuesta",
    type: "text",
    placeholder: "Dirección para notificación",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_petitionerPhoneNumber",
    label: "14. Número de teléfono del peticionario",
    type: "text",
    placeholder: "Número de teléfono",
    aid: { message: "" },
    require: true,
  },
];
export const I_doc2 = [
  {
    name: "1_entityCity",
    label:
      "1. Ciudad donde se ubica la entidad que vulneró el derecho fundamental de petición",
    type: "text",
    placeholder: "Ciudad de la entidad",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_citizenFullName",
    label:
      "2. Nombre completo del ciudadano al que se le vulneró el derecho fundamental de petición",
    type: "text",
    placeholder: "Nombre completo del ciudadano",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_violatingEntity",
    label: "3. Entidad que vulneró el derecho (Ejemplo: Alcaldía de Cali)",
    type: "text",
    placeholder: "Nombre de la entidad",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_identificationType",
    label:
      "4. Tipo de identificación del peticionario (ciudadano al que se le vulneró el derecho)",
    type: "text",
    placeholder: "Tipo de identificación",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_identificationNumber",
    label:
      "5. Número de identificación del ciudadano al que se le vulneró el derecho fundamental de petición",
    type: "text",
    placeholder: "Número de identificación",
    aid: { message: "" },
    require: true,
  },
  {
    name: "5_requestSubmissionDate",
    label:
      "6. Fecha en la que se radicó el derecho de petición al que no se le dio respuesta de parte de la entidad",
    type: "date",
    placeholder: "Seleccionar fecha",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_petitionDelivery",
    label:
      "7. Correo electrónico o dirección física a la que se envió la petición que no obtuvo respuesta",
    type: "text",
    placeholder: "Correo electrónico o dirección física",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_requestContent",
    label: "8. Indicar qué se solicitó en la petición que no obtuvo respuesta",
    type: "text",
    placeholder: "Contenido de la solicitud",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_entityRepresentativeName",
    label: "9. Indicar el nombre de quien representa a la entidad",
    type: "text",
    placeholder: "Nombre del representante de la entidad",
    aid: {
      message:
        "Si desea agregar el nombre del funcionario, puede buscaren Google indicando el cargo y la ciudad.",
      image: "first",
    },
    require: true,
  },
  {
    name: "1_requestNumber",
    label: "10. Número de radicado de la solicitud (OPCIONAL)",
    type: "text",
    placeholder: "Número de radicado",
    aid: { message: "" },
    require: false,
  },
  {
    name: "5_radicadoDate",
    label: "11. Fecha del radicado",
    type: "date",
    placeholder: "Seleccionar fecha",
    aid: { message: "" },
    require: true,
  },
  {
    name: "4_tutelaNotificationEmail",
    label:
      "12. El correo electrónico en el que desea recibir las notificaciones de la acción de tutela interpuesta",
    type: "email",
    placeholder: "Correo electrónico para notificaciones",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_tutelaNotificationAddress",
    label:
      "13. La dirección física en la que desea recibir las notificaciones de la acción de tutela interpuesta",
    type: "text",
    placeholder: "Dirección para notificaciones",
    aid: { message: "" },
    require: true,
  },
  {
    name: "1_citizenPhoneNumber",
    label:
      "14. Número de teléfono del ciudadano que interpone la acción de tutela",
    type: "text",
    placeholder: "Número de teléfono",
    aid: { message: "" },
    require: true,
  },
  {
    name: "4_violatingEntityEmail",
    label: "15. Correo electrónico de la entidad que vulneró el derecho",
    type: "email",
    placeholder: "Correo electrónico de la entidad",
    aid: {
      message:
        "Si requiere relacionar el correo y/o la dirección de la entidad a la que se dirige, puede buscaren Google como el ejemplo de la imagen.",
      image: "second",
    },
    require: true,
  },
  {
    name: "1_violatingEntityAddress",
    label: "16. Dirección física de la entidad que vulneró el derecho",
    type: "text",
    placeholder: "Dirección física de la entidad",
    aid: {
      message:
        "Si requiere relacionar el correo y/o la dirección de la entidad a la que se dirige, puede buscaren Google como el ejemplo de la imagen.",
      image: "second",
    },
    require: true,
  },
];
