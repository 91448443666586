import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Alert from "react-bootstrap/Alert";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const Login = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [error, setError] = useState("");
  const [msg, setMsg] = useState("");

  useEffect(() => {
    let login = secureLocalStorage.getItem("login");
    if (login) {
      navigate("/ml");
    }
    let logginStatus = secureLocalStorage.getItem("loginStatus");
    if (logginStatus) {
      setError(logginStatus);
      setTimeout(function () {
        secureLocalStorage.clear();
        setError("");
        // window.location.reload();
      }, 1000);
    }
    setTimeout(function () {
      setMsg("");
    }, 4000);
  }, [msg]);

  const handleInputChange = (e, type) => {
    switch (type) {
      case "user":
        setError("");
        setUser(e.target.value);
        if (e.target.value === "") {
          setError("Por favor completa el campo nombre de usuario");
        }
        break;
      case "pass":
        setError("");
        setPass(e.target.value);
        if (e.target.value === "") {
          setError("Por favor ingresa la contraseña ");
        }
        break;

      default:
        break;
    }
  };

  const handleLogin = async () => {
    if (user == "" || pass == "") {
      setError("Por favor completa los campos");
    } else {
      setError("");
      let data = {
        user: user,
        pass: pass,
        type: "login",
      };
      const pruebas =
        "http://localhost/practidocs/backend/controllers/userController.php";
      const webUrl =
        "https://practidocumentos.com/backend/controllers/userController.php";
      const res = await axios.post(webUrl, data);
      console.log(res.data);

      if (res.data.includes("loggedIn")) {
        secureLocalStorage.setItem("login", true);
        navigate("/dashboard", { replace: true });
      }
    }
  };

  return (
    <section className=" gradient-custom">
      <Container className="py-5 ">
        <Row className="d-flex justify-content-center align-items-center h-100">
          <Col className="col-12 col-md-8 col-lg-6 col-xl-5">
            <Card className="bg-dark text-white">
              <Card.Body className="p-5 text-center">
                <Row>
                  <Alert
                    show={error !== "" ? true : false}
                    variant="danger"
                    onClose={() => setError("")}
                    dismissible
                  >
                    <Alert.Heading>{error}</Alert.Heading>
                  </Alert>
                </Row>

                <div className="mb-md-5 mt-md-4 pb-5">
                  <h2 className="fw-bold mb-2 text-uppercase">Login</h2>
                  <p className="text-white-50 mb-5">
                    Please enter your login and password!
                  </p>
                  <Form>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="email"
                        id="u_Email"
                        className="form-control form-control-lg"
                        value={user}
                        onChange={(e) => handleInputChange(e, "user")}
                        required={true}
                      />
                      <Form.Label>Correo Electronico</Form.Label>
                    </Form.Group>

                    <div className="form-outline form-white mb-4">
                      <Form.Group>
                        <Form.Control
                          type="password"
                          id="u_Password"
                          className="form-control form-control-lg"
                          value={pass}
                          onChange={(e) => handleInputChange(e, "pass")}
                          required={true}
                          autoComplete="of"
                        />
                        <Form.Label>Contraseña</Form.Label>
                      </Form.Group>
                    </div>

                    <p className="small mb-5 pb-lg-2">
                      <a className="text-white-50" href="#!">
                        Forgot password?
                      </a>
                    </p>

                    <Button
                      className="btn btn-outline-light btn-lg px-5"
                      onClick={handleLogin}
                    >
                      Login
                    </Button>
                  </Form>

                  <div className="d-flex justify-content-center text-center mt-4 pt-1">
                    <a href="#!" className="text-white">
                      <i className="fab fa-facebook-f fa-lg"></i>
                    </a>
                    <a href="#!" className="text-white">
                      <i className="fab fa-twitter fa-lg mx-4 px-2"></i>
                    </a>
                    <a href="#!" className="text-white">
                      <i className="fab fa-google fa-lg"></i>
                    </a>
                  </div>
                </div>
                {/*
                                <div>
                                    <p className="mb-0">Don't have an account? <a href="#!" className="text-white-50 fw-bold">Sign Up</a>
                                    </p>
                                </div>*/}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default Login;
