import React, { createContext, useState } from 'react';

const SharedOrderDataContext = createContext();

export const SharedDataProvider = ({ children }) => {
  const [sharedOrderData, setSharedOrderData] = useState(initialData);

  const updateSharedData = (newData) => {
    setSharedOrderData({ ...sharedOrderData, ...newData });
  };

  return (
    <SharedOrderDataContext.Provider value={{ sharedOrderData, updateSharedData }}>
      {children}
    </SharedOrderDataContext.Provider>
  );
};

const initialData = {
    description:'',
    amount:0
 
};

export default SharedOrderDataContext;