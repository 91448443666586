import './App.css';
import React, { useState } from 'react';
import MainPage from './pages/mainPage';
import Login from './pages/login';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { LoginContext } from './helpers/context';
import { SharedDataProvider } from './helpers/sharedOrderContext';
import Dashboard from './pages/dashboard';
import Protected from './helpers/protected';
import Order_Response from './pages/order-Response';
import MainTestPage from './components/tests';
import ErrorPage from './pages/errorPage';



function App() {
  const router = createBrowserRouter([
    {
      path: '/', element: < MainPage />
    },
    {
      path: '/ml', element: < Login />
    },
    {
      path: '/dashboard', element: <Protected component={Dashboard} />
    },
    {
      path: '/response', element: <Order_Response />
    },
    {
      path: '/test', element: <MainTestPage />
    },{
      errorElement:<ErrorPage />,
    }


  ]);

  const [urlDownload, setDownloadURl] = useState({});
  return (
    <SharedDataProvider>

      <LoginContext.Provider value={{ urlDownload, setDownloadURl }}>
        <RouterProvider router={router} />
      </LoginContext.Provider>
    </SharedDataProvider>
  );
}

export default App;
