
const Help_images = {
    first: {
        link: require('../images/aids/1.png'),
        text: ''
    },
    second: {
        link: require('../images/aids/2.png'),
        text: ''
    },
    third: {
        link: require('../images/aids/3.png'),
        text: ''
    },
    fourth: {
        link: require('../images/aids/4.png'),
        text: ''
    },
    fifth: {
        link: require('../images/aids/5.png'),
        text: ''
    },
    sixth: {
        link: require('../images/aids/6.png'),
        text: ''
    },
    seventh: {
        link: require('../images/aids/7.png'),
        text: ''
    },
    eighth: {
        link: require('../images/aids/8.png'),
        text: ''
    },
    ninth: {
        link: require('../images/aids/9.png'),
        text: ''
    },
    tenth: {
        link: require('../images/aids/10.png'),
        text: ''
    },
    eleventh: {
        link: require('../images/aids/11.png'),
        text: ''
    },
}
export default Help_images;