import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Container } from 'react-bootstrap';
import mainImage from '../images/mainServices.jpg';


const MainServices = () => {

    return (
        <div >
            <Col className="paralaxbg services_bg" md={12} style={{ backgroundImage: `url(${mainImage})` }} >
                <Container fluid>
                    <Row>
                        <div id="mainServices" >
                            <Row className='d-flex  justify-content-center align-items-center'>
                                <Col className='d-flex justify-content-center align-items-center'>
                                    <Card className='serviceCard   text-white'>

                                        <Card.Body className='text-center'>
                                            <Card.Title>Defensa en procesos de cobro en contra de particulares.</Card.Title>
                                            <Card.Text className='mt-5'>
                                                Ofrecemos asesoría legal especializada para enfrentar procesos de cobro coactivo
                                                desarrollados por entidades públicas. Obtén ayuda especializada para resolver tus
                                                deudas y proteger tus derechos.
                                            </Card.Text>
                                            <Button className='secondaryColor wpp_button'><a className="wpp_text_link text-white" target="_blank" rel="noreferrer" href="https://api.whatsapp.com/send?phone=3225921651&text=Me%20interesa%20saber%20sobre%20las%20asesor%C3%ADas%20legales%20respecto%20a:Defensa%20en%20procesos%20de%20cobro%20en%20contra%20de%20particulares.">Hablar con un experto</a></Button>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col className='d-flex justify-content-center align-items-center '>
                                    <Card className='serviceCard text-white   '>

                                        <Card.Body className='text-center '>
                                            <Card.Title>Asesoría jurídica para procesos de cobro coactivo para entidades públicas.</Card.Title>
                                            <Card.Text className='mt-5'>
                                                Proporcionamos asesoría legal de calidad para entidades públicas en el desarrollo
                                                de los procesos de cobro coactivo en contra de deudores morosos; logrando así
                                                optimizar la gestión financiera y cumpliendo con eficacia los objetivos de recaudo
                                                e interrupción de la prescripción.
                                            </Card.Text>
                                            <Button className='secondaryColor wpp_button'><a className="wpp_text_link text-white" target="_blank" rel="noreferrer" href="https://api.whatsapp.com/send?phone=3225921651&text=Me%20interesa%20saber%20sobre%20las%20asesor%C3%ADas%20legales%20respecto%20a:Asesoría%20jurídica%20para%20procesos%20de%20cobro%20coactivo%20para%20entidades%20públicas.">Hablar con un experto</a></Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col className='d-flex justify-content-center align-items-center'>
                                    <Card className='serviceCard   text-white mt-3'>

                                        <Card.Body className='text-center'>
                                            <Card.Title>Asesoría jurídica para procesos de cobro coactivo para abogados.</Card.Title>
                                            <Card.Text className='mt-5'>
                                                Fortalecemos tus habilidades y conocimientos legales en esta área clave para el
                                                desarrollo de proyectos estatales.
                                            </Card.Text>
                                            <Button className='secondaryColor wpp_button'><a className="wpp_text_link text-white" target="_blank" rel="noreferrer" href="https://api.whatsapp.com/send?phone=3225921651&text=Me%20interesa%20saber%20sobre%20las%20asesor%C3%ADas%20legales%20respecto%20a:Asesoría%20jurídica%20para%20procesos%20de%20cobro%20coactivo%20para%20abogados.">Hablar con un experto</a></Button>
                                        </Card.Body>
                                    </Card>
                                </Col>


                            </Row>
                            <Row className='d-flex  justify-content-center align-items-center'>
                                <Col className='d-flex justify-content-center align-items-center'>

                                    <Card className='serviceCard  text-white  mt-3' >

                                        <Card.Body className='text-center'>
                                            <Card.Title>Elaboración de manuales de procedimiento de cobro coactivo.</Card.Title>
                                            <Card.Text className='mt-5'>
                                                Servicio destinado para Entidades públicas que requieren la proyección
                                                especializada del Reglamento interno de cartera y el manual de procedimiento de
                                                cobro coactivo ajustado a la normatividad municipal y nacional vigente.
                                            </Card.Text>
                                            <Button className='secondaryColor wpp_button'><a className="wpp_text_link text-white" target="_blank" rel="noreferrer" href="https://api.whatsapp.com/send?phone=3225921651&text=Me%20interesa%20saber%20sobre%20las%20asesor%C3%ADas%20legales%20respecto%20a:Elaboración%20de%20manuales%20de%20procedimiento%20de%20cobro%20coactivo.">Hablar con un experto</a></Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </div>


                    </Row>



                </Container>

            </Col>
        </div>





    );


};

export default MainServices;