import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import ScrollToAnchor from '../helpers/scrolltoAncher';
import Image from 'react-bootstrap/Image';
import Logo from '../images/practiDocsLogo.png';
import LogoText from '../images/practiDocsTextLogo.png'
function MainNavbar() {
const [expanded, setExpanded] = useState(false);

const navToggle=()=>{
  setExpanded(expanded?false:"expanded");
}
 
  return (

   

    <Navbar id="mainNavbar" variant="dark" expand="lg" fixed="top" className='mainColor'  expanded={expanded}>
      <Container >
   
        <Navbar.Brand href="#"><Image className='navLogo' src={Logo}></Image><Image className='navLogoText' src={LogoText}></Image></Navbar.Brand>
        <Navbar.Toggle onClick={navToggle} aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="ms-auto my-2 my-lg-0"
            style={{ maxHeight: '300px' }}
            navbarScroll
          >
            {/*
            <Form className="d-flex ml-auto">
              <Form.Control
                type="search"
                placeholder="Busca un documento"
                className="me-2"
                aria-label="Search"
              />
              <Button variant="outline-success">Buscar</Button>
            </Form>
            */}

            <Link className='nav-link' to="/#mainDocuments" onClick={navToggle}>Documentos</Link>
            <Link className='nav-link' to="/#mainServices" onClick={navToggle}>Servicios</Link>
            <Link className='nav-link' to="/#mainAbout" onClick={navToggle}>Nosotros</Link>
            <Link className='nav-link' to="/#mainContact" onClick={navToggle}>Contacto</Link>



          </Nav>

        </Navbar.Collapse>
      </Container>
    </Navbar>

  );
}

export default MainNavbar;